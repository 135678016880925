import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Theme,
  MobileStepper,
  Paper,
  Typography,
  useTheme,
  Grid,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useContext, useEffect, useState } from 'react';
import { ReportViewContext } from '../../store/reportViewContext';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    display: 'block',
    overflow: 'hidden',
    maxHeight: '35em',
    maxWidth: '35em',
  },
  button: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },

  formItem: {
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
    },
  },
}));

type Props = {
  imageModels: ReportImageModel[];
};

const ImageCarousel: React.FC<Props> = ({ imageModels }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { getsingleReportImage } = useContext(ReportViewContext);
  const { t } = useTranslation('common');

  const [images, setImages] = useState<tableRowImage[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [hasImages, setHasImages] = useState(false);

  useEffect(() => {
    if (imageModels.length > 0) {
      let ctr = 0; // show after all images are loaded
      const tempImages: React.SetStateAction<tableRowImage[]> = [];
      imageModels.forEach(async (imageModel) => {
        const image = await getsingleReportImage(
          imageModel.reportId,
          imageModel.imageIdentifier,
          imageModel.comment
        );
        if (image !== null) {
          tempImages.push(image);
        }
        ctr++;
        if (ctr >= imageModels.length) {
          setImages(tempImages);
          setHasImages(true);
        }
      });
      // } else {
      //   setImages([]);
      //   setHasImages(false);
    }
  }, [setHasImages, imageModels, getsingleReportImage, setImages]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <div>
      {hasImages ? (
        <div>
          <Paper square elevation={0}>
            <Typography>{images[activeStep].label}</Typography>
          </Paper>
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {images.map((step, index) => (
              <div key={step.imgPath}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <img
                    className={classes.img}
                    src={URL.createObjectURL(step.data)}
                    alt={step.label}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={images.length}
            position="static"
            variant="text"
            activeStep={activeStep}
            nextButton={
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleNext}
                disabled={activeStep === images.length - 1}
              >
                {t('next')}
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                {t('back')}
              </Button>
            }
          />
        </div>
      ) : (
        <Grid item lg={11} xs={12}>
          <TextField
            type="textarea"
            disabled
            value={t('noImages')}
            autoComplete="off"
            color="secondary"
            fullWidth
            variant="outlined"
            margin="normal"
          />
        </Grid>
      )}
    </div>
  );
};

export default ImageCarousel;
