import * as React from 'react';
import { useState, useContext } from 'react';
import { Card, CardContent, Grid, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useGetSiteName from '../utils/hooks/useGetSiteName';
import UserForm from './UserForm';
import { UserContext } from '../store/userContext';
import CardTitle from '../utils/components/CardTitle';
import ListCardActions from '../utils/components/ListCardActions';
import CardRow from '../utils/components/CardRow';
import LoadingData from '../utils/components/LoadingData';

type Props = {
  user: User;
  loading: boolean;
};

const UserCard: React.FC<Props> = ({ user, loading }: Props) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const {
    getUsers,
    editUser,
    editUserLoading,
    deleteUser,
    deleteUserLoading,
  } = useContext(UserContext);

  const { t } = useTranslation('users');
  const { getSiteName } = useGetSiteName();

  const handleEditSubmit = (data: UserFormDto) => {
    editUser(user.id, data, () => {
      setEditing(false);
      getUsers(true);
    });
  };

  const handleEditOpen = () => {
    setEditing(true);
  };

  const handleEditClose = () => {
    setEditing(false);
  };

  const handleDeleteSubmit = () => {
    deleteUser(user.id, () => {
      setDeleting(false);
      getUsers(true);
    });
  };

  const handleDeleteOpen = () => {
    setDeleting(true);
  };

  const handleDeleteClose = () => {
    setDeleting(false);
  };

  return (
    <Card>
      <CardTitle
        title={`${t('username')}: ${user.name}`}
        onEditClick={handleEditOpen}
        loading={loading}
      />
      <CardContent>
        <CardRow loading={loading} content={`${t('email')}: ${user.email}`} />
        <CardRow
          loading={loading}
          content={`${t('role')}: ${t(user.role.toLowerCase())}`}
        />
        <CardRow loading={loading} content={`${t('locale')}: ${user.locale}`} />
        <CardRow loading={loading} content={`${t('sites')}:`} />
        <Grid container spacing={1}>
          {user.siteIds?.map((s) => (
            <Grid key={s} item>
              <LoadingData loading={loading}>
                <Chip
                  variant="outlined"
                  label={getSiteName(s)}
                  color="secondary"
                />
              </LoadingData>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <ListCardActions
        loading={loading}
        ns="users"
        editing={editing}
        onEditClose={handleEditClose}
        deleting={deleting}
        onDeleteClick={handleDeleteOpen}
        onDeleteClose={handleDeleteClose}
        deleteLoading={deleteUserLoading}
        onDeleteConfirm={handleDeleteSubmit}
        editingContent={
          <UserForm
            onSubmit={handleEditSubmit}
            defaultValues={user}
            buttonText={t('common:edit')}
            loading={editUserLoading}
            cancelButton
            onCancel={handleEditClose}
          />
        }
      />
    </Card>
  );
};

export default UserCard;
