import * as React from 'react';
import { useState, useContext } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ApplicationLinkForm from './ApplicationLinkForm';
import CardTitle from '../utils/components/CardTitle';
import ListCardActions from '../utils/components/ListCardActions';
import CardRow from '../utils/components/CardRow';
import { ApplicationLinkContext } from '../store/applicationLinkContext';

type Props = {
  applicationLink: ApplicationLink;
  loading: boolean;
};

const ApplicationLinkCard: React.FC<Props> = ({
  applicationLink,
  loading,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const {
    getApplicationLinks,
    editApplicationLink,
    editApplicationLinkLoading,
    deleteApplicationLink,
    deleteApplicationLinkLoading,
  } = useContext(ApplicationLinkContext);

  const { t } = useTranslation('applicationlinks');

  const handleEditSubmit = (data: ApplicationLinkFormDto) => {
    editApplicationLink(applicationLink.id, data, () => {
      setEditing(false);
      getApplicationLinks(true);
    });
  };

  const handleEditOpen = () => {
    setEditing(true);
  };

  const handleEditClose = () => {
    setEditing(false);
  };

  const handleDeleteSubmit = () => {
    deleteApplicationLink(applicationLink.id, () => {
      setDeleting(false);
      getApplicationLinks(true);
    });
  };

  const handleDeleteOpen = () => {
    setDeleting(true);
  };

  const handleDeleteClose = () => {
    setDeleting(false);
  };

  return (
    <Card>
      <CardTitle
        title={`${t('linkText')}: ${applicationLink.linkText}`}
        onEditClick={handleEditOpen}
        loading={loading}
      />
      <CardContent>
        <CardRow
          loading={loading}
          content={`${t('linkUrl')}: ${applicationLink.linkUrl}`}
        />
        <CardRow
          loading={loading}
          content={`${t('ordering')}: ${applicationLink.ordering}`}
        />
        <CardRow
          loading={loading}
          content={`${t('hidden')}: ${applicationLink.hidden}`}
        />
      </CardContent>
      <ListCardActions
        loading={loading}
        ns="applicationLinks"
        editing={editing}
        onEditClose={handleEditClose}
        deleting={deleting}
        onDeleteClick={handleDeleteOpen}
        onDeleteClose={handleDeleteClose}
        deleteLoading={deleteApplicationLinkLoading}
        onDeleteConfirm={handleDeleteSubmit}
        editingContent={
          <ApplicationLinkForm
            onSubmit={handleEditSubmit}
            defaultValues={applicationLink}
            buttonText={t('common:edit')}
            loading={editApplicationLinkLoading}
            cancelButton
            onCancel={handleEditClose}
          />
        }
      />
    </Card>
  );
};

export default ApplicationLinkCard;
