import { useContext, useCallback } from 'react';

import { UIContext } from '../../store/uiContext';

function useNotify(): (n: NotificationType) => void {
  const { addNotification } = useContext(UIContext);

  const notify = useCallback(
    (notification: NotificationType) => {
      addNotification(notification);
    },
    [addNotification]
  );

  return notify;
}

export default useNotify;
