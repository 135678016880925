const loadingContacts: Contact[] = [
  {
    id: 0,
    siteId: 0,
    contactType: 'ORDER',
    locale: 'EN',
    email: 'test.contact@test.com',
  },
  {
    id: 1,
    siteId: 0,
    contactType: 'REMINDER',
    locale: 'FI',
    email: 'test.contact@test.com',
  },
];

export default loadingContacts;
