import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Theme,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
  FormControl,
  Grid,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SiteInput from '../utils/components/SiteInput';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(5),
  },
  formItem: {
    minHeight: 120,
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
    },
  },
  button: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}));

type Props = {
  onSubmit: (data: ContactFormDto) => void;
  onCancel?: () => void;
  cancelButton?: boolean;
  defaultValues?: ContactFormDto;
  buttonText: string;
  loading: boolean;
};

const ContactForm: React.FC<Props> = ({
  onSubmit,
  onCancel = () => null,
  defaultValues = {
    siteId: null,
    email: '',
    locale: 'FI',
    contactType: 'HELP',
  },
  buttonText,
  cancelButton = false,
  loading,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('contacts');

  const { register, handleSubmit, errors, control } = useForm<ContactFormDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const requiredMessage = t('validation:required');
  const emailMessage = t('validation:email');
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
              pattern: {
                value: /^[^@]+@[^@]+\.[^@]+$/,
                message: emailMessage,
              },
            })}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="email"
            label={t('email')}
            name="email"
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <FormControl
            variant="outlined"
            error={!!errors.contactType}
            margin="normal"
            fullWidth
            style={{ display: 'block' }}
          >
            <InputLabel id="type-label">{t('contactType')}</InputLabel>
            <Controller
              as={
                <Select>
                  <MenuItem value="HELP">{t('help')}</MenuItem>
                  <MenuItem value="REMINDER">{t('reminder')}</MenuItem>
                  <MenuItem value="ORDER">{t('order')}</MenuItem>
                </Select>
              }
              rules={{ required: requiredMessage }}
              fullWidth
              variant="outlined"
              color="secondary"
              id="contactType"
              labelId="type-label"
              label={t('contactType')}
              name="contactType"
              control={control}
              defaultValue="HELP"
            />
            <FormHelperText>{errors.contactType?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <FormControl
            variant="outlined"
            error={!!errors.locale}
            margin="normal"
            fullWidth
            style={{ display: 'block' }}
          >
            <InputLabel id="locale-label">{t('locale')}</InputLabel>
            <Controller
              as={
                <Select>
                  <MenuItem value="FI">FI</MenuItem>
                  <MenuItem value="EN">EN</MenuItem>
                </Select>
              }
              rules={{ required: requiredMessage }}
              fullWidth
              variant="outlined"
              color="secondary"
              id="locale"
              labelId="locale-label"
              label={t('locale')}
              name="locale"
              control={control}
            />
            <FormHelperText>{errors.locale?.message}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Controller
            name="siteId"
            control={control}
            render={({ onChange, value }) => (
              <SiteInput
                value={value}
                onChange={onChange}
                error={!!errors.siteId}
                helperText={errors.siteId?.message}
              />
            )}
            defaultValue={null}
            rules={{ required: requiredMessage }}
          />
        </Grid>
        <Grid item container lg={6} xs={10} direction="row">
          {cancelButton && (
            <Button
              variant="contained"
              color="secondary"
              classes={{ root: classes.button }}
              onClick={onCancel}
            >
              {t('common:cancel')}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            classes={{ root: classes.button }}
            disabled={loading}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactForm;
