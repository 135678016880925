import * as React from 'react';
import { useState, useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useGetSiteName from '../utils/hooks/useGetSiteName';
import StorageForm from './StorageForm';
import { StorageContext } from '../store/storageContext';
import DataCell from '../utils/components/DataCell';
import ActionCell from '../utils/components/ActionCell';
import DeleteDialog from '../utils/components/DeleteDialog';

type Props = {
  storage: StorageType;
  loading: boolean;
  colCount: number;
};

const StorageRow: React.FC<Props> = ({ storage, loading, colCount }: Props) => {
  const [editing, setEditing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation('storages');
  const { getSiteName } = useGetSiteName();
  const {
    getStorages,
    editStorage,
    editStorageLoading,
    deleteStorage,
    deleteStorageLoading,
  } = useContext(StorageContext);

  const handleEditToggle = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  const handleSubmit = (data: StorageFormDto) => {
    editStorage(storage.id, data, () => {
      setEditing(false);
      getStorages(true);
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    deleteStorage(storage.id, () => {
      handleDialogClose();
      getStorages(true);
    });
  };

  const reportDate = storage.lastReportDate
    ? new Date(storage.lastReportDate).toLocaleDateString()
    : '';

  const inventoryDate = storage.lastInventoryDate
    ? new Date(storage.lastInventoryDate).toLocaleDateString()
    : '';

  return (
    <>
      <TableRow hover>
        <DataCell first loading={loading}>
          {storage.name}
        </DataCell>
        <DataCell loading={loading}>{reportDate}</DataCell>
        <DataCell loading={loading}>{inventoryDate}</DataCell>
        <DataCell loading={loading}>{getSiteName(storage.siteId)}</DataCell>
        <ActionCell
          editing={editing}
          onEditClick={handleEditToggle}
          onDeleteClick={handleDeleteConfirmation}
          loading={loading}
          showDelete
        />
      </TableRow>
      {editing && (
        <TableRow>
          <TableCell colSpan={colCount}>
            <StorageForm
              onSubmit={handleSubmit}
              defaultValues={storage}
              buttonText={t('common:edit')}
              loading={editStorageLoading}
            />
          </TableCell>
        </TableRow>
      )}
      <DeleteDialog
        loading={deleteStorageLoading}
        open={dialogOpen}
        onClose={handleDialogClose}
        ns="storages"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default StorageRow;
