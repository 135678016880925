import * as React from 'react';
import { useState, useContext } from 'react';
import { Chip, Grid, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import SiteForm from './SiteForm';
import { SiteContext } from '../store/siteContext';
import DataCell from '../utils/components/DataCell';
import ActionCell from '../utils/components/ActionCell';
import DeleteDialog from '../utils/components/DeleteDialog';
import LoadingData from '../utils/components/LoadingData';
import useGetUserName from '../utils/hooks/useGetUserName';
import ContactChip from '../utils/components/ContactChip';

type Props = {
  site: Site;
  loading: boolean;
  colCount: number;
};

const SiteRow: React.FC<Props> = ({ site, loading, colCount }: Props) => {
  const [editing, setEditing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation('sites');
  const { getUserName } = useGetUserName();
  const {
    getSites,
    editSite,
    editSiteLoading,
    deleteSite,
    deleteSiteLoading,
  } = useContext(SiteContext);

  const handleEditToggle = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  const handleSubmit = (data: SiteFormDto) => {
    editSite(site.id, data, () => {
      setEditing(false);
      getSites(true);
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    deleteSite(site.id, () => {
      handleDialogClose();
      getSites(true);
    });
  };

  return (
    <>
      <TableRow hover>
        <DataCell first loading={loading}>
          {site.name}
        </DataCell>
        <DataCell loading={loading}>{site.location}</DataCell>
        <DataCell loading={false}>
          <Grid container spacing={1}>
            {site.userIds?.map((u) => (
              <Grid key={u} item>
                <LoadingData loading={loading}>
                  <Chip
                    variant="outlined"
                    label={getUserName(u)}
                    color="secondary"
                  />
                </LoadingData>
              </Grid>
            ))}
          </Grid>
        </DataCell>
        <DataCell loading={false}>
          <Grid container spacing={1}>
            {site.contactIds?.map((c) => (
              <Grid key={c} item>
                <LoadingData loading={loading}>
                  <ContactChip contactId={c} />
                </LoadingData>
              </Grid>
            ))}
          </Grid>
        </DataCell>
        <ActionCell
          editing={editing}
          onEditClick={handleEditToggle}
          onDeleteClick={handleDeleteConfirmation}
          loading={loading}
          showDelete
        />
      </TableRow>
      {editing && (
        <TableRow>
          <TableCell colSpan={colCount}>
            <SiteForm
              onSubmit={handleSubmit}
              defaultValues={site}
              buttonText={t('common:edit')}
              loading={editSiteLoading}
            />
          </TableCell>
        </TableRow>
      )}
      <DeleteDialog
        loading={deleteSiteLoading}
        open={dialogOpen}
        onClose={handleDialogClose}
        ns="sites"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default SiteRow;
