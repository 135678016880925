const loadingSites: Site[] = [
  {
    id: 0,
    name: 'Test Site',
    location: 'TestSiteLocation',
    contactIds: [0, 1],
    storageIds: [0, 1],
    userIds: [0, 1],
  },
  {
    id: 1,
    name: 'Test Site 2',
    location: 'TestSiteLocation',
    contactIds: [0, 1],
    storageIds: [0, 1],
    userIds: [0, 1],
  },
];

export default loadingSites;
