import * as React from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
  AppBar as MuiAppBar,
  Toolbar,
  Box,
  IconButton,
  MenuItem,
  Menu,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import valmetLogo from '../assets/valmet_logo@3x.png';
import { AuthContext } from '../store/authContext';
import Navigation from '../navigation/Navigation';
import HideOnScroll from './HideOnScroll';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0px 4px 4px #0000003D',
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    display: 'flex',
    paddingRight: 0,
  },
  appBarShift: {
    width: '100%',
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    height: '3rem',
  },
  rightContainer: {
    marginLeft: 'auto',
    display: 'flex',
    height: '100%',
  },
  paperRounded: {
    borderRadius: 2,
  },
  menuListItem: {
    paddingRight: theme.spacing(8),
  },
  moreButton: {
    alignSelf: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  menuIcon: {
    minWidth: 36,
  },
}));

const AppBar: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const { logout, isLoggedIn } = useContext(AuthContext);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    logout();
    history.push('/');
    handleClose();
  };

  return (
    <HideOnScroll>
      <MuiAppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img src={valmetLogo} alt="Valmet logo" className={classes.logo} />
          <Box className={classes.rightContainer}>
            {!isSmall && isLoggedIn && <Navigation />}
            <IconButton
              color="secondary"
              onClick={handleClick}
              className={classes.moreButton}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={open}
              onClose={handleClose}
              BackdropProps={{ invisible: false }}
              classes={{ paper: classes.paperRounded }}
            >
              {isLoggedIn && (
                <MenuItem
                  onClick={handleLogout}
                  className={classes.menuListItem}
                >
                  <ListItemIcon className={classes.menuIcon}>
                    <ExitToAppIcon />
                  </ListItemIcon>

                  <ListItemText primary={t('auth:logout')} />
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Toolbar>
        {isSmall && isLoggedIn && <Navigation />}
      </MuiAppBar>
    </HideOnScroll>
  );
};

export default AppBar;
