import * as React from 'react';
import { useState, useContext } from 'react';
import { Card, CardContent, Grid, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import SiteForm from './SiteForm';
import { SiteContext } from '../store/siteContext';
import CardTitle from '../utils/components/CardTitle';
import ListCardActions from '../utils/components/ListCardActions';
import CardRow from '../utils/components/CardRow';
import useGetUserName from '../utils/hooks/useGetUserName';
import LoadingData from '../utils/components/LoadingData';
import ContactChip from '../utils/components/ContactChip';

type Props = {
  site: Site;
  loading: boolean;
};

const SiteCard: React.FC<Props> = ({ site, loading }: Props) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { getUserName } = useGetUserName();
  const {
    getSites,
    editSite,
    editSiteLoading,
    deleteSite,
    deleteSiteLoading,
  } = useContext(SiteContext);

  const { t } = useTranslation('sites');

  const handleEditSubmit = (data: SiteFormDto) => {
    editSite(site.id, data, () => {
      setEditing(false);
      getSites(true);
    });
  };

  const handleEditOpen = () => {
    setEditing(true);
  };

  const handleEditClose = () => {
    setEditing(false);
  };

  const handleDeleteSubmit = () => {
    deleteSite(site.id, () => {
      setDeleting(false);
      getSites(true);
    });
  };

  const handleDeleteOpen = () => {
    setDeleting(true);
  };

  const handleDeleteClose = () => {
    setDeleting(false);
  };

  return (
    <Card>
      <CardTitle
        title={`${t('name')}: ${site.name}`}
        onEditClick={handleEditOpen}
        loading={loading}
      />
      <CardContent>
        <CardRow
          loading={loading}
          content={`${t('location')}: ${site.location}`}
        />
        <CardRow loading={loading} content={`${t('users')}:`} />
        <Grid container spacing={1}>
          {site.userIds?.map((u) => (
            <Grid key={u} item>
              <LoadingData loading={loading}>
                <Chip
                  variant="outlined"
                  label={getUserName(u)}
                  color="secondary"
                />
              </LoadingData>
            </Grid>
          ))}
        </Grid>
        <CardRow loading={loading} content={`${t('contacts')}:`} />
        <Grid container spacing={1}>
          {site.contactIds?.map((c) => (
            <Grid key={c} item>
              <LoadingData loading={loading}>
                <ContactChip contactId={c} />
              </LoadingData>
            </Grid>
          ))}
        </Grid>
      </CardContent>
      <ListCardActions
        loading={loading}
        ns="sites"
        editing={editing}
        onEditClose={handleEditClose}
        deleting={deleting}
        onDeleteClick={handleDeleteOpen}
        onDeleteClose={handleDeleteClose}
        deleteLoading={deleteSiteLoading}
        onDeleteConfirm={handleDeleteSubmit}
        editingContent={
          <SiteForm
            onSubmit={handleEditSubmit}
            defaultValues={site}
            buttonText={t('common:edit')}
            loading={editSiteLoading}
            cancelButton
            onCancel={handleEditClose}
          />
        }
      />
    </Card>
  );
};

export default SiteCard;
