import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Theme, Grid, Checkbox } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(5),
  },
  formItem: {
    minHeight: 120,
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
    },
  },
  button: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}));

type Props = {
  onSubmit: (data: ReportFormDto) => void;
  onCancel?: () => void;
  cancelButton?: boolean;
  defaultValues?: ReportFormDto;
  buttonText: string;
  loading: boolean;
};

const ReportForm: React.FC<Props> = ({
  onSubmit,
  onCancel = () => null,
  defaultValues = {
    date: new Date(),
    siteName: '',
    storageName: '',
    reporterName: '',
    inventory: false,
    imageCount: 0,
    inventoryLogs: [],
    stockingLogs: [],
  },
  buttonText,
  cancelButton = false,
  loading,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('reports');
  const [inventory, setToggle] = useState(false);

  const { register, handleSubmit, errors } = useForm<ReportFormDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const requiredMessage = t('validation:required');
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
            })}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="siteName"
            label={t('siteName')}
            name="siteName"
            error={!!errors.siteName}
            helperText={errors.siteName?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
            })}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="storageName"
            label={t('storageName')}
            name="storageName"
            error={!!errors.storageName}
            helperText={errors.storageName?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
            })}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="reporterName"
            label={t('reporterName')}
            name="reporterName"
            error={!!errors.reporterName}
            helperText={errors.reporterName?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <Checkbox
            inputRef={register({})}
            value={inventory}
            id="inventory"
            name="inventory"
            color="primary"
            onChange={() => {
              setToggle(!inventory);
            }}
          />
          {t('inventory')}
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
            })}
            type="date"
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="date"
            name="date"
            error={!!errors.date}
            helperText={errors.date?.message}
          />
        </Grid>
        <Grid item container lg={6} xs={10} direction="row">
          {cancelButton && (
            <Button
              variant="contained"
              color="secondary"
              classes={{ root: classes.button }}
              onClick={onCancel}
            >
              {t('common:cancel')}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            classes={{ root: classes.button }}
            disabled={loading}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ReportForm;
