import * as React from 'react';
import { CardHeader, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  title: {
    wordBreak: 'break-word',
  },
  actions: {
    marginLeft: 'auto',
  },
}));

interface Props {
  loading: boolean;
  title: string;
  onEditClick: () => void;
}

const CardTitle: React.FC<Props> = ({ loading, title, onEditClick }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const editButton = (
    <IconButton
      className={classes.actions}
      title={t('edit')}
      color="secondary"
      onClick={onEditClick}
    >
      <EditIcon />
    </IconButton>
  );
  return (
    <CardHeader
      classes={{ title: classes.title }}
      action={loading ? <Skeleton>{editButton}</Skeleton> : editButton}
      title={
        loading ? (
          <Skeleton>
            <span>{title}</span>
          </Skeleton>
        ) : (
          title
        )
      }
    />
  );
};

export default CardTitle;
