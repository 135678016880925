import { useState, useCallback, useEffect, useRef, useContext } from 'react';
import { AuthContext } from '../../store/authContext';

interface RequestParams {
  url: string;
  method?: string;
  body?: BodyInit | null;
  headers?: HeadersInit;
}

interface UseHttpClient {
  loading: boolean;
  error: Error | undefined;
  sendRequest: <T>({ url, method, body }: RequestParams) => Promise<T>;
  getImage: ({ url, method, body }: RequestParams) => Promise<Blob | null>;
  clearError: () => void;
}

export default function UseHttpClient(): UseHttpClient {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();

  const activeRequests = useRef<AbortController[]>([]);

  const { refreshSession } = useContext(AuthContext);

  const sendRequest = useCallback(
    async ({ url, method = 'GET', body = null }: RequestParams) => {
      // Get token by refreshing authContext
      let token = '';
      try {
        token = await refreshSession();
      } catch (err) {
        throw new Error(err);
      }

      if (!token) {
        return null;
      }
      setLoading(true);
      const httpAbortCtrl = new AbortController();
      activeRequests.current.push(httpAbortCtrl);

      // Create headers with the auth header and content-type
      const headers = new Headers({
        Authorization: `Bearer ${token}`,
      });
      headers.append('Content-Type', 'application/json');

      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        });

        let responseData;
        if (response.headers.has('content-type')) {
          if (response.headers.get('content-type') === 'application/json') {
            responseData = await response.json();
          } else {
            responseData = await response.text();
          }
        }

        activeRequests.current = activeRequests.current.filter(
          (ctrl) => ctrl !== httpAbortCtrl
        );

        if (!response.ok) {
          if (response.status === 403) {
            throw new Error('Forbidden');
          }
          throw new Error(
            responseData ? responseData.message || responseData : 'Error'
          );
        }
        setLoading(false);
        return responseData;
      } catch (e) {
        setLoading(false);
        setError(e ? e.message || e : 'Error');
        throw e;
      }
    },
    [refreshSession]
  );

  const getImage = useCallback(
    async ({ url, method = 'GET', body = null }: RequestParams) => {
      // Get token by refreshing authContext
      let token = '';
      try {
        token = await refreshSession();
      } catch (err) {
        throw new Error(err);
      }

      if (!token) {
        return null;
      }
      setLoading(true);
      const httpAbortCtrl = new AbortController();
      activeRequests.current.push(httpAbortCtrl);

      // Create headers with the auth header and content-type
      const headers = new Headers({
        Authorization: `Bearer ${token}`,
      });
      // headers.append('Content-Type', 'application/json');

      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + url, {
          method,
          body,
          headers,
          signal: httpAbortCtrl.signal,
        });

        const responseData = await response.blob();

        activeRequests.current = activeRequests.current.filter(
          (ctrl) => ctrl !== httpAbortCtrl
        );

        if (!response.ok) {
          if (response.status === 403) {
            throw new Error('Forbidden');
          }
          throw new Error(response.statusText);
        }
        setLoading(false);
        return responseData;
      } catch (e) {
        setLoading(false);
        setError(e ? e.message || e : 'Error');
        throw e;
      }
    },
    [refreshSession]
  );

  const clearError = () => {
    setError(undefined);
  };

  // Cleanup possible ongoing requests when unmounting
  useEffect(() => {
    const reqs = activeRequests.current;
    return () => {
      reqs.forEach((ctrl) => ctrl.abort());
    };
  }, []);

  return { loading, error, sendRequest, getImage, clearError };
}
