import * as React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/hooks/useErrorMessage';

import UseHttpClient from '../utils/hooks/useHttpClient';
import useNotify from '../utils/hooks/useNotify';
import { AuthContext } from './authContext';

type ReportContext = {
  reports: Report[];
  getReportsLoading: boolean;
  addReportLoading: boolean;
  editReportLoading: boolean;
  deleteReportLoading: boolean;
  getReports: (skipLoading?: boolean, queryParams?: QueryParams, onSuccess?: (results: TableModel<Report>) => void) => void;
  addReport: (
    reportToAdd: ReportFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  editReport: (
    id: number,
    reportToEdit: ReportFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  deleteReport: (id: number, onSuccess?: () => void) => Promise<void>;
};

const ReportContext = createContext<ReportContext>({
  reports: [],
  getReportsLoading: false,
  addReportLoading: false,
  editReportLoading: false,
  deleteReportLoading: false,
  getReports: () => null,
  addReport: () => Promise.reject(),
  editReport: () => Promise.reject(),
  deleteReport: () => Promise.reject(),
});

interface QueryParams {
  inventoryReports?: boolean;
  page?: number;
  pageSize?: number;
  storageId?: number;
  siteId?: number;
};
const getUrlEncodedParams = (params: QueryParams) => {
  return Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&');
};

const ReportProvider: React.FC = ({ children }) => {
  const [reports, setReports] = useState<Report[]>([]);
  const [getReportsLoading, setGetReportsLoading] = useState(false);
  const [addReportLoading, setAddReportLoading] = useState(false);
  const [editReportLoading, setEditReportLoading] = useState(false);
  const [deleteReportLoading, setDeleteReportLoading] = useState(false);
  const { sendRequest } = UseHttpClient();
  const { t } = useTranslation('reports');
  const { errorMessage } = useErrorMessage();
  const { isLoggedIn } = useContext(AuthContext);

  const notify = useNotify();

  const getReports = useCallback(
    async (skipLoading = false, queryParams?: QueryParams, onSuccess?: (results: TableModel<Report>) => void) => {
      if (!skipLoading) {
        setGetReportsLoading(true);
      }
      try {
        const params = queryParams ? `?${getUrlEncodedParams(queryParams)}` : '';
        const resData = await sendRequest<TableModel<Report>>({
          url: `reports${params}`,
        });
        if (resData && resData.reportHeaders && resData.reportHeaders.length) {
          if (onSuccess) {
            onSuccess(resData);
          }
          setReports(resData.reportHeaders);
        }
      } catch (error) {
        errorMessage({
          ns: 'reports',
          error,
          defaultTranslation: 'list',
        });
      } finally {
        if (!skipLoading) {
          setGetReportsLoading(false);
        }
      }
    },
    [sendRequest, errorMessage]
  );

  // Get all reports on mount
  useEffect(() => {
    if (isLoggedIn) {
      getReports();
    }
  }, [getReports, isLoggedIn]);

  const addReport = useCallback(
    async (reportToAdd: ReportFormDto, onSuccess?: () => void) => {
      setAddReportLoading(true);
      try {
        await sendRequest({
          url: `reports/new`,
          method: 'POST',
          body: JSON.stringify(reportToAdd),
        });
        notify({ message: t('added') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'reports',
          error,
          defaultTranslation: 'add',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
            {
              text: 'email: ',
              translation: 'email',
            },
          ],
        });
      } finally {
        setAddReportLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const editReport = useCallback(
    async (id: number, reportToEdit: ReportFormDto, onSuccess?: () => void) => {
      setEditReportLoading(true);
      try {
        await sendRequest({
          url: `reports/${id}`,
          method: 'PUT',
          body: JSON.stringify(reportToEdit),
        });
        notify({ message: t('edited') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'reports',
          error,
          defaultTranslation: 'edit',
          cases: [
            {
              text: 'email: ',
              translation: 'email',
            },
          ],
        });
      } finally {
        setEditReportLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const deleteReport = useCallback(
    async (id: number, onSuccess?: () => void) => {
      setDeleteReportLoading(true);
      try {
        await sendRequest({
          url: `reports/${id}`,
          method: 'DELETE',
        });
        notify({ message: t('deleted') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'reports',
          error,
          defaultTranslation: 'delete',
        });
      } finally {
        setDeleteReportLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  return (
    <ReportContext.Provider
      value={{
        reports,
        getReportsLoading,
        getReports,
        addReportLoading,
        addReport,
        editReport,
        editReportLoading,
        deleteReport,
        deleteReportLoading,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export { ReportProvider, ReportContext };
