import * as React from 'react';
import { useContext, useMemo } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { SiteContext } from '../../store/siteContext';

import useGetSiteName from '../hooks/useGetSiteName';

type Props = {
  onChange: (site: number | null) => void;
  value: number | null;
  error: boolean;
  helperText: string | undefined;
};

const SiteInput: React.FC<Props> = ({
  onChange: setSelectedSite,
  value: selectedSite,
  error,
  helperText,
}: Props) => {
  const { t } = useTranslation('sites');
  const { getSiteName } = useGetSiteName();

  const { sites } = useContext(SiteContext);

  const siteIds = useMemo(() => sites.map((s) => s.id), [sites]);

  if (!sites || !sites.length) {
    return <Typography>{t('noSites')}</Typography>;
  }

  const handleChange = (newSite: number | null) => {
    setSelectedSite(newSite);
  };

  return (
    <Autocomplete
      id="site-selection"
      options={siteIds}
      value={selectedSite}
      onChange={(_e: unknown, newSite) => handleChange(newSite)}
      getOptionLabel={(site) => getSiteName(site)}
      fullWidth
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          error={error}
          helperText={helperText}
          label={t('selectSingle')}
          variant="outlined"
        />
      )}
    />
  );
};

export default SiteInput;
