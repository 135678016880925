import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';

import './i18n/i18n';
import { UIProvider } from './store/uiContext';
import { AuthProvider } from './store/authContext';
import VPTApp from './VPTApp';
import { UserProvider } from './store/userContext';
import { SiteProvider } from './store/siteContext';
import { StorageProvider } from './store/storageContext';
import { ProductProvider } from './store/productContext';
import { ContactProvider } from './store/contactContext';
import { ProductgroupProvider } from './store/productgroupContext';
import { ApplicationLinkProvider } from './store/applicationLinkContext';
import { ReportProvider } from './store/reportContext';
import { ReportViewProvider } from './store/reportViewContext';

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_POOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID,
  },
});

const App: React.FC = () => {
  return (
    <Router>
      <UIProvider>
        <AuthProvider>
          <UserProvider>
            <SiteProvider>
              <StorageProvider>
                <ProductProvider>
                  <ApplicationLinkProvider>
                    <ContactProvider>
                      <ProductgroupProvider>
                        <ReportProvider>
                          <ReportViewProvider>
                            <VPTApp />
                          </ReportViewProvider>
                        </ReportProvider>
                      </ProductgroupProvider>
                    </ContactProvider>
                  </ApplicationLinkProvider>
                </ProductProvider>
              </StorageProvider>
            </SiteProvider>
          </UserProvider>
        </AuthProvider>
      </UIProvider>
    </Router>
  );
};

export default App;
