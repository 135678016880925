import * as React from 'react';
import { useState, useContext } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useGetSiteName from '../utils/hooks/useGetSiteName';
import ContactForm from './ContactForm';
import { ContactContext } from '../store/contactContext';
import CardTitle from '../utils/components/CardTitle';
import ListCardActions from '../utils/components/ListCardActions';
import CardRow from '../utils/components/CardRow';

type Props = {
  contact: Contact;
  loading: boolean;
};

const ContactCard: React.FC<Props> = ({ contact, loading }: Props) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const {
    getContacts,
    editContact,
    editContactLoading,
    deleteContact,
    deleteContactLoading,
  } = useContext(ContactContext);

  const { t } = useTranslation('contacts');
  const { getSiteName } = useGetSiteName();

  const handleEditSubmit = (data: ContactFormDto) => {
    editContact(contact.id, data, () => {
      setEditing(false);
      getContacts(true);
    });
  };

  const handleEditOpen = () => {
    setEditing(true);
  };

  const handleEditClose = () => {
    setEditing(false);
  };

  const handleDeleteSubmit = () => {
    deleteContact(contact.id, () => {
      setDeleting(false);
      getContacts(true);
    });
  };

  const handleDeleteOpen = () => {
    setDeleting(true);
  };

  const handleDeleteClose = () => {
    setDeleting(false);
  };

  return (
    <Card>
      <CardTitle
        title={`${t('email')}: ${contact.email}`}
        onEditClick={handleEditOpen}
        loading={loading}
      />
      <CardContent>
        <CardRow
          loading={loading}
          content={`${t('contactType')}: ${t(
            contact.contactType.toLowerCase()
          )}`}
        />
        <CardRow
          loading={loading}
          content={`${t('locale')}: ${contact.locale}`}
        />
        <CardRow
          loading={loading}
          content={`${t('site')}: ${getSiteName(contact.siteId)}`}
        />
      </CardContent>
      <ListCardActions
        loading={loading}
        ns="contacts"
        editing={editing}
        onEditClose={handleEditClose}
        deleting={deleting}
        onDeleteClick={handleDeleteOpen}
        onDeleteClose={handleDeleteClose}
        deleteLoading={deleteContactLoading}
        onDeleteConfirm={handleDeleteSubmit}
        editingContent={
          <ContactForm
            onSubmit={handleEditSubmit}
            defaultValues={contact}
            buttonText={t('common:edit')}
            loading={editContactLoading}
            cancelButton
            onCancel={handleEditClose}
          />
        }
      />
    </Card>
  );
};

export default ContactCard;
