import * as React from 'react';
import { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Attachment } from '@material-ui/icons';
import DataCell from '../utils/components/DataCell';
import ActionCell from '../utils/components/ActionCell';
import ReportView from './ReportView/ReportView';

type Props = {
  report: Report;
  loading: boolean;
  colCount: number;
};

const ReportRow: React.FC<Props> = ({ report, loading, colCount }: Props) => {
  const [editing, setEditing] = useState(false);
  const handleEditToggle = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  function dateToString(date: Date): string {
    let text = '-';
    if (date != null) {
      text = new Date(date).toLocaleDateString();
    }
    return text;
  }

  return (
    <>
      <TableRow hover>
        <DataCell first loading={loading}>
          {report.siteName}
        </DataCell>
        <DataCell loading={loading}>{report.storageName}</DataCell>
        <DataCell loading={loading}>{report.reporterName}</DataCell>
        <DataCell loading={loading}>{dateToString(report.date)}</DataCell>
        <DataCell loading={loading}>{report.imageCount > 0 && <Attachment color="secondary" />}</DataCell>
        <ActionCell
          action='expand'
          editing={editing}
          onEditClick={handleEditToggle}
          onDeleteClick={handleEditToggle}
          loading={loading}
          showDelete={false}
        />
      </TableRow>
      {
        editing && (
          <TableRow>
            <TableCell colSpan={colCount}>
              <ReportView key={report.id} report={report} />
            </TableCell>
          </TableRow>
        )
      }
    </>
  );
};

export default ReportRow;
