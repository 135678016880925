import * as React from 'react';
import { createContext, useState, useCallback } from 'react';
import useErrorMessage from '../utils/hooks/useErrorMessage';
import UseHttpClient from '../utils/hooks/useHttpClient';

type ReportViewContext = {
  getReportDetailsLoading: boolean;
  getReportDetails: (
    reportId: number,
    skipLoading?: boolean,
    onSuccess?: (results: ReportDetail) => void
  ) => Promise<void>;
  reportDetails?: ReportDetail;
  getReportImagesLoading: boolean;
  getReportImageModels: (
    reportId: number,
    skipLoading?: boolean,
    onSuccess?: (results: ReportImageModel[]) => void
  ) => Promise<void>;
  reportImageModels: ReportImageModel[];
  getsingleReportImage: (
    reportId: number,
    imageId: string,
    label: string
  ) => Promise<tableRowImage | null>;
};

const ReportViewContext = createContext<ReportViewContext>({
  getReportDetailsLoading: false,
  getReportDetails: () => Promise.reject(),
  reportDetails: undefined,
  getReportImagesLoading: false,
  getReportImageModels: () => Promise.reject(),
  reportImageModels: [],
  getsingleReportImage: () => Promise.reject(),
});

const ReportViewProvider: React.FC = ({ children }) => {
  const [reportImageModels, setReportImageModels] = useState<
    ReportImageModel[]
  >([]);
  const [reportDetails, setDetails] = useState<ReportDetail>();
  const [getReportDetailsLoading, setGetReportDetailsLoading] = useState(false);
  const [getReportImagesLoading, setGetReportImagesLoading] = useState(true);

  const { getImage } = UseHttpClient();
  const { sendRequest } = UseHttpClient();
  const { errorMessage } = useErrorMessage();

  const getReportDetails = useCallback(
    async (
      reportId: number,
      skipLoading = false,
      onSuccess?: (results: ReportDetail) => void
    ) => {
      if (!skipLoading) {
        setGetReportDetailsLoading(true);
      }
      try {
        const resData = await sendRequest<ReportDetail>({
          url: `reporthistory/message/${reportId}`,
        });
        if (resData) {
          setDetails(resData);
        }
        if (onSuccess) {
          onSuccess(resData);
        }
      } catch (error) {
        errorMessage({
          ns: 'reports',
          error,
          defaultTranslation: 'details',
        });
      } finally {
        setGetReportDetailsLoading(false);
      }
    },
    [sendRequest, errorMessage]
  );

  const getReportImageModels = useCallback(
    async (
      reportId: number,
      skipLoading = false,
      onSuccess?: (results: ReportImageModel[]) => void
    ) => {
      if (!skipLoading) {
        setGetReportImagesLoading(true);
      }
      try {
        const resData = await sendRequest<ReportImageModel[]>({
          url: `report/image/${reportId}`,
        });
        setReportImageModels(resData);
        if (onSuccess) {
          onSuccess(resData);
        }
      } catch (error) {
        errorMessage({
          ns: 'reports',
          error,
          defaultTranslation: 'images',
        });
      } finally {
        setGetReportImagesLoading(false);
      }
    },
    [sendRequest, errorMessage]
  );

  const getsingleReportImage = useCallback(
    async (reportId: number, imageId: string, label: string) => {
      try {
        const data = await getImage({
          url: `report/image/${reportId}/${imageId}`,
        });
        if (data !== null) {
          const imgPath = `report/image/${reportId}/${imageId}`;
          const reportImage: tableRowImage = { label, imgPath, data };
          // reportImage = {
          //   data: resData,
          //   label: label,
          //   imgPath: `report/image/${reportId}/${imageId}`,
          // };

          return reportImage;
        }
        throw new Error('no img');
      } catch (error) {
        errorMessage({
          ns: 'reports',
          error,
          defaultTranslation: 'image',
        });
        return null;
      }
    },
    [errorMessage, getImage]
  );

  return (
    <ReportViewContext.Provider
      value={{
        getReportDetailsLoading,
        getReportDetails,
        reportDetails,
        getReportImagesLoading,
        getReportImageModels,
        reportImageModels,
        getsingleReportImage,
      }}
    >
      {children}
    </ReportViewContext.Provider>
  );
};

export { ReportViewProvider, ReportViewContext };
