import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { List, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import NavItem from './NavItem';

const useStyles = makeStyles((theme: Theme) => ({
  navigation: {
    ...theme.mixins.toolbar,
    padding: 0,
    display: 'flex',
  },
}));

const Navigation: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation('nav');
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const contactName = isSmall ? 'contactsShort' : 'contacts';

  return (
    <List component="nav" className={classes.navigation}>
      <NavItem to="/users" name={t('users')} key="users" exact />
      <NavItem to="/sites" name={t('sites')} key="sites" exact />
      <NavItem to="/storages" name={t('storages')} key="storages" exact />
      <NavItem to="/products" name={t('products')} key="products" exact />
      <NavItem
        to="/productgroups"
        name={t('productgroups')}
        key="productgroups"
        exact
      />
      <NavItem to="/reports" name={t('reports')} key="reports" exact />
      <NavItem
        to="/applicationlinks"
        name={t('applicationlinks')}
        key="applicationlinks"
        exact
      />
      <NavItem to="/contacts" name={t(contactName)} key="contacts" exact />
    </List>
  );
};

export default Navigation;
