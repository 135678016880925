/* eslint-disable @typescript-eslint/ban-ts-comment */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// the translations
import * as commonEn from './en/common.json';
import * as authEn from './en/auth.json';
import * as validationEn from './en/validation.json';
import * as languageEn from './en/language.json';
import * as navEn from './en/nav.json';
import * as usersEn from './en/users.json';
import * as sitesEn from './en/sites.json';
import * as storagesEn from './en/storages.json';
import * as productsEn from './en/products.json';
import * as contactsEn from './en/contacts.json';
import * as productgroupsEn from './en/productgroups.json';
import * as applicationLinksEn from './en/applicationlinks.json';
import * as reportsEn from './en/reports.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        common: commonEn.t,
        auth: authEn.t,
        validation: validationEn.t,
        language: languageEn.t,
        nav: navEn.t,
        users: usersEn.t,
        sites: sitesEn.t,
        storages: storagesEn.t,
        products: productsEn.t,
        contacts: contactsEn.t,
        productgroups: productgroupsEn.t,
        applicationlinks: applicationLinksEn.t,
        reports: reportsEn.t,
      },
    },
    lng: 'en',
    keySeparator: '.',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
