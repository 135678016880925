const loadingProducts: Product[] = [
  {
    id: 0,
    code: 'Test code',
    name: 'Test product',
    description: 'Test description',
    dimensions: 'Test dimensions',
    unit: 'Test unit',
    productGroupId: null,
  },
  {
    id: 1,
    code: 'Test code',
    name: 'Test product',
    description: 'Test description',
    dimensions: 'Test dimensions',
    unit: 'Test unit',
    productGroupId: 0,
  },
];

export default loadingProducts;
