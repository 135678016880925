import * as React from 'react';
import { useState, useContext } from 'react';
import { Grid, Fab, Dialog, useTheme, Zoom } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

import UserCard from './UserCard';
import { UserContext } from '../store/userContext';
import UserForm from './UserForm';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  lastCard: {
    marginBottom: theme.spacing(10),
  },
}));

type Props = {
  users: User[];
  loading: boolean;
};

const UserListMobile: React.FC<Props> = ({ users, loading }: Props) => {
  const classes = useStyles();
  const [adding, setAdding] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const { addUser, getUsers, addUserLoading } = useContext(UserContext);

  const handleAddSubmit = (data: UserFormDto) => {
    addUser(data, () => {
      setAdding(false);
      getUsers(true);
    });
  };

  const handleAddOpen = () => {
    setAdding(true);
  };

  const handleAddClose = () => {
    setAdding(false);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Grid container spacing={3}>
      {users.map((u, index) => (
        <Grid
          key={u.id}
          item
          xs={12}
          className={index === users.length - 1 ? classes.lastCard : ''}
        >
          <UserCard user={u} loading={loading} />
        </Grid>
      ))}
      <Zoom in timeout={transitionDuration} unmountOnExit>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={handleAddOpen}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <Dialog open={adding} onClose={handleAddClose}>
        <UserForm
          onSubmit={handleAddSubmit}
          buttonText={t('common:add')}
          loading={addUserLoading}
          cancelButton
          onCancel={handleAddClose}
        />
      </Dialog>
    </Grid>
  );
};

export default UserListMobile;
