import * as React from 'react';
import { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ContactContext } from '../store/contactContext';
import ContactListMobile from './ContactListMobile';
import loadingContacts from './loadingContacts';
import DesktopList from '../utils/components/DesktopList';
import ContactForm from './ContactForm';
import ContactRow from './ContactRow';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
}));

const ContactList: React.FC = () => {
  const classes = useStyles();
  const {
    getContacts,
    contacts,
    getContactsLoading,
    addContact,
    addContactLoading,
  } = useContext(ContactContext);
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('contacts');

  useEffect(() => {
    getContacts();
  }, [getContacts]);

  return (
    <Container maxWidth="lg">
      <Typography
        align="center"
        color="secondary"
        variant="h3"
        className={classes.title}
      >
        {t('contacts')}
      </Typography>
      {isMediumOrSmaller ? (
        <ContactListMobile
          contacts={getContactsLoading ? loadingContacts : contacts}
          loading={getContactsLoading}
        />
      ) : (
        <DesktopList<Contact, ContactFormDto>
          headers={['email', 'contactType', 'locale', 'site']}
          data={getContactsLoading ? loadingContacts : contacts}
          loading={getContactsLoading}
          ns="contacts"
          add={addContact}
          get={getContacts}
          emptyKey="noContacts"
          renderAddForm={({ onSubmit, buttonText }) => (
            <ContactForm
              loading={addContactLoading}
              onSubmit={onSubmit}
              buttonText={buttonText}
            />
          )}
          renderBody={({ data, loading, columnCount }) => (
            <ContactRow
              key={data.id}
              contact={data}
              loading={loading}
              colCount={columnCount}
            />
          )}
          showAdd
        />
      )}
    </Container>
  );
};

export default ContactList;
