import * as React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/hooks/useErrorMessage';

import UseHttpClient from '../utils/hooks/useHttpClient';
import useNotify from '../utils/hooks/useNotify';
import { AuthContext } from './authContext';

type ProductContext = {
  products: Product[];
  getProductsLoading: boolean;
  addProductLoading: boolean;
  editProductLoading: boolean;
  deleteProductLoading: boolean;
  getProducts: (skipLoading?: boolean) => void;
  addProduct: (
    productToAdd: ProductFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  editProduct: (
    id: number,
    productToEdit: ProductFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  deleteProduct: (id: number, onSuccess?: () => void) => Promise<void>;
};

const ProductContext = createContext<ProductContext>({
  products: [],
  getProductsLoading: false,
  addProductLoading: false,
  editProductLoading: false,
  deleteProductLoading: false,
  getProducts: () => null,
  addProduct: () => Promise.reject(),
  editProduct: () => Promise.reject(),
  deleteProduct: () => Promise.reject(),
});

const ProductProvider: React.FC = ({ children }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [getProductsLoading, setGetProductsLoading] = useState(false);
  const [addProductLoading, setAddProductLoading] = useState(false);
  const [editProductLoading, setEditProductLoading] = useState(false);
  const [deleteProductLoading, setDeleteProductLoading] = useState(false);
  const { sendRequest } = UseHttpClient();
  const { t } = useTranslation('products');
  const { errorMessage } = useErrorMessage();
  const { isLoggedIn } = useContext(AuthContext);

  const notify = useNotify();

  const getProducts = useCallback(
    async (skipLoading = false) => {
      if (!skipLoading) {
        setGetProductsLoading(true);
      }
      try {
        const resData = await sendRequest<Product[]>({
          url: 'products',
        });
        if (resData && resData.length) {
          setProducts(resData);
        }
      } catch (error) {
        errorMessage({
          ns: 'products',
          error,
          defaultTranslation: 'list',
        });
      } finally {
        if (!skipLoading) {
          setGetProductsLoading(false);
        }
      }
    },
    [sendRequest, errorMessage]
  );

  // Get all products on mount
  useEffect(() => {
    if (isLoggedIn) {
      getProducts();
    }
  }, [getProducts, isLoggedIn]);

  const addProduct = useCallback(
    async (productToAdd: ProductFormDto, onSuccess?: () => void) => {
      setAddProductLoading(true);
      try {
        await sendRequest({
          url: 'products',
          method: 'POST',
          body: JSON.stringify(productToAdd),
        });
        notify({ message: t('added') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'products',
          error,
          defaultTranslation: 'add',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
          ],
        });
      } finally {
        setAddProductLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const editProduct = useCallback(
    async (
      id: number,
      productToEdit: ProductFormDto,
      onSuccess?: () => void
    ) => {
      setEditProductLoading(true);
      try {
        await sendRequest({
          url: `products/${id}`,
          method: 'PUT',
          body: JSON.stringify(productToEdit),
        });
        notify({ message: t('edited') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'products',
          error,
          defaultTranslation: 'edit',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
          ],
        });
      } finally {
        setEditProductLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const deleteProduct = useCallback(
    async (id: number, onSuccess?: () => void) => {
      setDeleteProductLoading(true);
      try {
        await sendRequest({
          url: `products/${id}`,
          method: 'DELETE',
        });
        notify({ message: t('deleted') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'products',
          error,
          defaultTranslation: 'delete',
        });
      } finally {
        setDeleteProductLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  return (
    <ProductContext.Provider
      value={{
        products,
        getProductsLoading,
        getProducts,
        addProductLoading,
        addProduct,
        editProduct,
        editProductLoading,
        deleteProduct,
        deleteProductLoading,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export { ProductProvider, ProductContext };
