const loadingStorages: StorageType[] = [
  {
    id: 0,
    name: 'Test Storage',
    lastReportDate: '2020-11-17T06:43:52.106Z',
    lastInventoryDate: '2020-11-17T06:43:52.106Z',
    siteId: 0,
  },
  {
    id: 1,
    name: 'Test Storage 2',
    lastReportDate: '2020-11-17T06:43:52.106Z',
    lastInventoryDate: '2020-11-17T06:43:52.106Z',
    siteId: 0,
  },
];

export default loadingStorages;
