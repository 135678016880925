import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, Grid, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useContext } from 'react';
import ImageCarousel from '../../utils/components/ImageCarousel';
import { ReportViewContext } from '../../store/reportViewContext';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(5),
  },
  formItem: {
    minHeight: 120,
    whiteSpace: 'pre-line',
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
    },
  },
  button: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}));

type Props = {
  report: Report;
};

const ReportView: React.FC<Props> = ({ report }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('reports');
  const { getReportDetails, getReportImageModels } = useContext(
    ReportViewContext
  );

  const [reportDetails, setReportDetails] = React.useState<ReportDetail | null>(
    null
  );
  const [reportImageModels, setReportImageModels] = React.useState<
    ReportImageModel[] | null
  >(null);

  useEffect(() => {
    if (report.id >= 0) {
      getReportDetails(report.id, false, setReportDetails);
      getReportImageModels(report.id, false, setReportImageModels);
    }
  }, [report.id, getReportDetails, getReportImageModels]);

  return (
    <Grid container spacing={2} justify="center" alignItems="center">
      <Grid item lg={6} xs={12} className={classes.formItem}>
        <Paper square elevation={0}>
          <Typography id="subject_header" variant="h6" color="primary">
            {t('subject')}
          </Typography>
          <Typography id="subject" color="secondary">
            {reportDetails?.subject}
          </Typography>
        </Paper>
      </Grid>
      <Grid item lg={6} xs={12} className={classes.formItem}>
        <Paper square elevation={0}>
          <Typography id="body_header" variant="h6" color="primary">
            {t('body')}
          </Typography>
          <Typography id="body" color="secondary">
            {reportDetails?.body}
          </Typography>
        </Paper>
      </Grid>

      <Grid item lg={6} xs={12} className={classes.formItem}>
        {reportImageModels ? (
          <ImageCarousel key={report.id} imageModels={reportImageModels} />
        ) : (
          t('noImages')
        )}
      </Grid>
    </Grid>
  );
};

export default ReportView;
