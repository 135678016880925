import * as React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/hooks/useErrorMessage';

import UseHttpClient from '../utils/hooks/useHttpClient';
import useNotify from '../utils/hooks/useNotify';
import { AuthContext } from './authContext';

type StorageContext = {
  storages: StorageType[];
  getStoragesLoading: boolean;
  addStorageLoading: boolean;
  editStorageLoading: boolean;
  deleteStorageLoading: boolean;
  getStorages: (skipLoading?: boolean) => void;
  getStorageListBySite: (siteId: number, onSuccess?: (result: StorageType[]) => void) => void;
  addStorage: (
    storageToAdd: StorageFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  editStorage: (
    id: number,
    storageToEdit: StorageFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  deleteStorage: (id: number, onSuccess?: () => void) => Promise<void>;
};

const StorageContext = createContext<StorageContext>({
  storages: [],
  getStoragesLoading: false,
  addStorageLoading: false,
  editStorageLoading: false,
  deleteStorageLoading: false,
  getStorages: () => null,
  getStorageListBySite: () => Promise.reject(),
  addStorage: () => Promise.reject(),
  editStorage: () => Promise.reject(),
  deleteStorage: () => Promise.reject(),
});

const StorageProvider: React.FC = ({ children }) => {
  const [storages, setStorages] = useState<StorageType[]>([]);
  const [getStoragesLoading, setGetStoragesLoading] = useState(false);
  const [addStorageLoading, setAddStorageLoading] = useState(false);
  const [editStorageLoading, setEditStorageLoading] = useState(false);
  const [deleteStorageLoading, setDeleteStorageLoading] = useState(false);
  const { sendRequest } = UseHttpClient();
  const { t } = useTranslation('storages');
  const { errorMessage } = useErrorMessage();
  const { isLoggedIn } = useContext(AuthContext);

  const notify = useNotify();

  const getStorages = useCallback(
    async (skipLoading = false) => {
      if (!skipLoading) {
        setGetStoragesLoading(true);
      }
      try {
        const resData = await sendRequest<StorageType[]>({
          url: 'storages'
        });
        if (resData && resData.length) {
          setStorages(resData);
        }
      } catch (error) {
        errorMessage({
          ns: 'storages',
          error,
          defaultTranslation: 'list',
        });
      } finally {
        if (!skipLoading) {
          setGetStoragesLoading(false);
        }
      }
    },
    [sendRequest, errorMessage]
  );

  const getStorageListBySite = useCallback(
    async (siteId, onSuccess?) => {
      const resData = await sendRequest<StorageType[]>({
        url: `storagelist/${siteId}`
      });
      if (resData && resData.length && onSuccess) {
        onSuccess(resData);
      }
    },
    [sendRequest]
  );

  // Get all storages on mount
  useEffect(() => {
    if (isLoggedIn) {
      getStorages();
    }
  }, [getStorages, isLoggedIn]);

  const addStorage = useCallback(
    async (storageToAdd: StorageFormDto, onSuccess?: () => void) => {
      setAddStorageLoading(true);
      try {
        await sendRequest({
          url: 'storages',
          method: 'POST',
          body: JSON.stringify(storageToAdd),
        });
        notify({ message: t('added') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'storages',
          error,
          defaultTranslation: 'add',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
          ],
        });
      } finally {
        setAddStorageLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const editStorage = useCallback(
    async (
      id: number,
      storageToEdit: StorageFormDto,
      onSuccess?: () => void
    ) => {
      setEditStorageLoading(true);
      try {
        await sendRequest({
          url: `storages/${id}`,
          method: 'PUT',
          body: JSON.stringify(storageToEdit),
        });
        notify({ message: t('edited') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'storages',
          error,
          defaultTranslation: 'edit',
        });
      } finally {
        setEditStorageLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const deleteStorage = useCallback(
    async (id: number, onSuccess?: () => void) => {
      setDeleteStorageLoading(true);
      try {
        await sendRequest({
          url: `storages/${id}`,
          method: 'DELETE',
        });
        notify({ message: t('deleted') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'storages',
          error,
          defaultTranslation: 'delete',
        });
      } finally {
        setDeleteStorageLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  return (
    <StorageContext.Provider
      value={{
        storages,
        getStoragesLoading,
        getStorages,
        getStorageListBySite,
        addStorageLoading,
        addStorage,
        editStorage,
        editStorageLoading,
        deleteStorage,
        deleteStorageLoading,
      }}
    >
      {children}
    </StorageContext.Provider>
  );
};

export { StorageProvider, StorageContext };
