import { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SiteContext } from '../../store/siteContext';

export default function useGetSiteName(): {
  getSiteName: (id: number | null) => string;
} {
  const { t } = useTranslation('sites');
  const { sites } = useContext(SiteContext);

  const getSiteName = useCallback(
    (id: number | null) => {
      if (id === null) {
        return '';
      }
      const site = sites.find((s) => s.id === id);
      if (site && site.name) {
        return site.name;
      }
      return t('unknown');
    },
    [sites, t]
  );

  return { getSiteName };
}
