import * as React from 'react';
import { Skeleton } from '@material-ui/lab';

interface Props {
  children: React.ReactNode;
  loading: boolean;
}

const LoadingData: React.FC<Props> = ({ loading, children }: Props) => {
  return loading ? (
    <Skeleton>
      <span>{children}</span>
    </Skeleton>
  ) : (
    <>{children}</>
  );
};

export default LoadingData;
