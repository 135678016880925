import * as React from 'react';
import { createContext, useState, useCallback } from 'react';

type UIContext = {
  languageDrawerOpen: boolean;
  setLanguageDrawerOpen: (state: boolean) => void;
  notifications: NotificationType[];
  addNotification: (notification: NotificationType) => void;
  hideNotification: () => void;
};

const UIContext = createContext<UIContext>({
  languageDrawerOpen: false,
  setLanguageDrawerOpen: () => null,
  notifications: [] as NotificationType[],
  addNotification: () => null,
  hideNotification: () => null,
});

const UIProvider: React.FC = ({ children }) => {
  const [languageDrawerOpen, setLanguageDrawerOpen] = useState(false);
  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const addNotification = useCallback((n: NotificationType) => {
    setNotifications((prevNotifs) => {
      const curNotifs = [...prevNotifs];
      curNotifs.push(n);
      return curNotifs;
    });
  }, []);

  const hideNotification = useCallback(() => {
    setNotifications((prevNotifs) => {
      const curNotifs = [...prevNotifs];
      curNotifs.shift();
      return curNotifs;
    });
  }, []);

  return (
    <UIContext.Provider
      value={{
        languageDrawerOpen,
        setLanguageDrawerOpen,
        notifications,
        addNotification,
        hideNotification,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export { UIProvider, UIContext };
