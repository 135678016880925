import * as React from 'react';
import { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { StorageContext } from '../store/storageContext';
import StorageListMobile from './StorageListMobile';
import loadingStorages from './loadingStorages';
import DesktopList from '../utils/components/DesktopList';
import StorageForm from './StorageForm';
import StorageRow from './StorageRow';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
}));

const StorageList: React.FC = () => {
  const classes = useStyles();
  const {
    getStorages,
    storages,
    getStoragesLoading,
    addStorage,
    addStorageLoading,
  } = useContext(StorageContext);
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('storages');

  useEffect(() => {
    getStorages();
  }, [getStorages]);

  return (
    <Container maxWidth="lg">
      <Typography
        align="center"
        color="secondary"
        variant="h3"
        className={classes.title}
      >
        {t('storages')}
      </Typography>
      {isMediumOrSmaller ? (
        <StorageListMobile
          storages={getStoragesLoading ? loadingStorages : storages}
          loading={getStoragesLoading}
        />
      ) : (
        <DesktopList<StorageType, StorageFormDto>
          headers={['name', 'lastReportDate', 'lastInventoryDate', 'site']}
          data={getStoragesLoading ? loadingStorages : storages}
          loading={getStoragesLoading}
          ns="storages"
          add={addStorage}
          get={getStorages}
          emptyKey="noStorages"
          renderAddForm={({ onSubmit, buttonText }) => (
            <StorageForm
              loading={addStorageLoading}
              onSubmit={onSubmit}
              buttonText={buttonText}
            />
          )}
          renderBody={({ data, loading, columnCount }) => (
            <StorageRow
              key={data.id}
              storage={data}
              loading={loading}
              colCount={columnCount}
            />
          )}
          showAdd
        />
      )}
    </Container>
  );
};

export default StorageList;
