import * as React from 'react';
import { useState, useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CheckCircle, Block } from '@material-ui/icons';

import DataCell from '../utils/components/DataCell';
import ActionCell from '../utils/components/ActionCell';
import DeleteDialog from '../utils/components/DeleteDialog';
import { ApplicationLinkContext } from '../store/applicationLinkContext';
import ApplicationLinkForm from './ApplicationLinkForm';

type Props = {
  applicationLink: ApplicationLink;
  loading: boolean;
  colCount: number;
};

const ApplicationLinkRow: React.FC<Props> = ({
  applicationLink,
  loading,
  colCount,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation('applicationlinks');
  const {
    getApplicationLinks,
    editApplicationLink,
    editApplicationLinkLoading,
    deleteApplicationLink,
    deleteApplicationLinkLoading,
  } = useContext(ApplicationLinkContext);

  const handleEditToggle = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  const handleSubmit = (data: ApplicationLinkFormDto) => {
    editApplicationLink(applicationLink.id, data, () => {
      setEditing(false);
      getApplicationLinks(true);
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    deleteApplicationLink(applicationLink.id, () => {
      handleDialogClose();
      getApplicationLinks(true);
    });
  };

  return (
    <>
      <TableRow hover>
        <DataCell first loading={loading}>
          {applicationLink.linkText}
        </DataCell>
        <DataCell loading={loading}>{applicationLink.linkUrl}</DataCell>
        <DataCell loading={loading}>{applicationLink.ordering}</DataCell>
        <DataCell loading={loading}>
          {applicationLink.hidden ? <CheckCircle color="primary" /> : <Block />}
        </DataCell>
        <ActionCell
          editing={editing}
          onEditClick={handleEditToggle}
          onDeleteClick={handleDeleteConfirmation}
          loading={loading}
          showDelete
        />
      </TableRow>
      {editing && (
        <TableRow>
          <TableCell colSpan={colCount}>
            <ApplicationLinkForm
              onSubmit={handleSubmit}
              defaultValues={applicationLink}
              buttonText={t('common:edit')}
              loading={editApplicationLinkLoading}
            />
          </TableCell>
        </TableRow>
      )}
      <DeleteDialog
        loading={deleteApplicationLinkLoading}
        open={dialogOpen}
        onClose={handleDialogClose}
        ns="applicationlinks"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default ApplicationLinkRow;
