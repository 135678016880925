import * as React from 'react';
import { useContext, useEffect } from 'react';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
} from 'react-router-dom';
import ContactList from './contacts/ContactList';

import Layout from './layout/Layout';
import Login from './login/Login';
import ProductgroupList from './productgroups/ProductgroupList';
import ProductList from './products/ProductList';
import SiteList from './sites/SiteList';
import StorageList from './storages/StorageList';
import { AuthContext } from './store/authContext';
import UserList from './users/UserList';
import ApplicationLinksList from './ApplicationLinks/ApplicationLinksList';
import ReportListPage from './reports/ReportListPage';

const VPTApp: React.FC = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  // If not logged in, redirect to /login
  useEffect(() => {
    if (isLoggedIn === false) {
      history.push('/login');
    }
  }, [isLoggedIn, history, pathname]);

  return (
    <Layout>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/users" />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/users" exact>
          <UserList />
        </Route>
        <Route path="/sites" exact>
          <SiteList />
        </Route>
        <Route path="/storages" exact>
          <StorageList />
        </Route>
        <Route path="/products" exact>
          <ProductList />
        </Route>
        <Route path="/productgroups" exact>
          <ProductgroupList />
        </Route>
        <Route path="/contacts" exact>
          <ContactList />
        </Route>
        <Route path="/applicationlinks" exact>
          <ApplicationLinksList />
        </Route>
        <Route path="/reports" exact>
          <ReportListPage />
        </Route>
        {/* 404 -> Redirect to user list */}
        <Route path="*">
          <Redirect to="/users" />
        </Route>
      </Switch>
    </Layout>
  );
};

export default VPTApp;
