import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Theme, Grid, Checkbox } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(5),
  },
  formItem: {
    minHeight: 120,
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
    },
  },
  button: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}));

type Props = {
  onSubmit: (data: ApplicationLinkFormDto) => void;
  onCancel?: () => void;
  cancelButton?: boolean;
  defaultValues?: ApplicationLinkFormDto;
  buttonText: string;
  loading: boolean;
};

const ApplicationLinkForm: React.FC<Props> = ({
  onSubmit,
  onCancel = () => null,
  defaultValues = { linkText: '', linkUrl: '', ordering: 10, hidden: false },
  buttonText,
  cancelButton = false,
  loading,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('applicationlinks');
  const [hidden, setToggle] = useState(false);

  const { register, handleSubmit, errors } = useForm<ApplicationLinkFormDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const requiredMessage = t('validation:required');
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
            })}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="linkText"
            label={t('linkText')}
            name="linkText"
            autoFocus
            error={!!errors.linkText}
            helperText={errors.linkText?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
            })}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="linkUrl"
            label={t('linkUrl')}
            name="linkUrl"
            error={!!errors.linkUrl}
            helperText={errors.linkUrl?.message}
          />
        </Grid>

        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
            })}
            type="number"
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="ordering"
            label={t('ordering')}
            name="ordering"
            error={!!errors.ordering}
            helperText={errors.ordering?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <Checkbox
            inputRef={register({})}
            value={hidden}
            id="hidden"
            name="hidden"
            color="primary"
            onChange={() => {
              setToggle(!hidden);
            }}
          />
          {t('hidden')}
        </Grid>

        <Grid item container lg={6} xs={10} direction="row">
          {cancelButton && (
            <Button
              variant="contained"
              color="secondary"
              classes={{ root: classes.button }}
              onClick={onCancel}
            >
              {t('common:cancel')}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            classes={{ root: classes.button }}
            disabled={loading}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ApplicationLinkForm;
