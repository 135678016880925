import * as React from 'react';
import { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ApplicationLinkContext } from '../store/applicationLinkContext';
import ApplicationLinksListMobile from './ApplicationLinkListMobile';
import loadingApplicationLinks from './loadingApplicationLinks';
import DesktopList from '../utils/components/DesktopList';
import ApplicationLinkForm from './ApplicationLinkForm';
import ApplicationLinkRow from './ApplicationLinkRow';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
}));

const ApplicationLinksList: React.FC = () => {
  const classes = useStyles();
  const {
    getApplicationLinks,
    applicationLinks,
    getApplicationLinksLoading,
    addApplicationLink,
    addApplicationLinkLoading,
  } = useContext(ApplicationLinkContext);
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('applicationlinks');

  useEffect(() => {
    getApplicationLinks();
  }, [getApplicationLinks]);

  return (
    <Container maxWidth="lg">
      <Typography
        align="center"
        color="secondary"
        variant="h3"
        className={classes.title}
      >
        {t('applicationlinks')}
      </Typography>
      {isMediumOrSmaller ? (
        <ApplicationLinksListMobile
          applicationLinks={
            getApplicationLinksLoading
              ? loadingApplicationLinks
              : applicationLinks
          }
          loading={getApplicationLinksLoading}
        />
      ) : (
        <DesktopList<ApplicationLink, ApplicationLinkFormDto>
          headers={['linkText', 'linkUrl', 'ordering', 'hidden']}
          data={
            getApplicationLinksLoading
              ? loadingApplicationLinks
              : applicationLinks
          }
          loading={getApplicationLinksLoading}
          ns="applicationlinks"
          add={addApplicationLink}
          get={getApplicationLinks}
          emptyKey="noApplicationLinks"
          renderAddForm={({ onSubmit, buttonText }) => (
            <ApplicationLinkForm
              loading={addApplicationLinkLoading}
              onSubmit={onSubmit}
              buttonText={buttonText}
            />
          )}
          renderBody={({ data, loading, columnCount }) => (
            <ApplicationLinkRow
              key={data.id}
              applicationLink={data}
              loading={loading}
              colCount={columnCount}
            />
          )}
          showAdd
        />
      )}
    </Container>
  );
};

export default ApplicationLinksList;
