import * as React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/hooks/useErrorMessage';

import UseHttpClient from '../utils/hooks/useHttpClient';
import useNotify from '../utils/hooks/useNotify';
import { AuthContext } from './authContext';

type ProductgroupContext = {
  productgroups: Productgroup[];
  getProductgroupsLoading: boolean;
  addProductgroupLoading: boolean;
  editProductgroupLoading: boolean;
  deleteProductgroupLoading: boolean;
  getProductgroups: (skipLoading?: boolean) => void;
  addProductgroup: (
    productgroupToAdd: ProductgroupFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  editProductgroup: (
    id: number,
    productgroupToEdit: ProductgroupFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  deleteProductgroup: (id: number, onSuccess?: () => void) => Promise<void>;
};

const ProductgroupContext = createContext<ProductgroupContext>({
  productgroups: [],
  getProductgroupsLoading: false,
  addProductgroupLoading: false,
  editProductgroupLoading: false,
  deleteProductgroupLoading: false,
  getProductgroups: () => null,
  addProductgroup: () => Promise.reject(),
  editProductgroup: () => Promise.reject(),
  deleteProductgroup: () => Promise.reject(),
});

const ProductgroupProvider: React.FC = ({ children }) => {
  const [productgroups, setProductgroups] = useState<Productgroup[]>([]);
  const [getProductgroupsLoading, setGetProductgroupsLoading] = useState(false);
  const [addProductgroupLoading, setAddProductgroupLoading] = useState(false);
  const [editProductgroupLoading, setEditProductgroupLoading] = useState(false);
  const [deleteProductgroupLoading, setDeleteProductgroupLoading] = useState(
    false
  );
  const { sendRequest } = UseHttpClient();
  const { t } = useTranslation('productgroups');
  const { errorMessage } = useErrorMessage();
  const { isLoggedIn } = useContext(AuthContext);

  const notify = useNotify();

  const getProductgroups = useCallback(
    async (skipLoading = false) => {
      if (!skipLoading) {
        setGetProductgroupsLoading(true);
      }
      try {
        const resData = await sendRequest<Productgroup[]>({
          url: 'productgroups',
        });
        if (resData && resData.length) {
          setProductgroups(resData);
        }
      } catch (error) {
        errorMessage({
          ns: 'productgroups',
          error,
          defaultTranslation: 'list',
        });
      } finally {
        if (!skipLoading) {
          setGetProductgroupsLoading(false);
        }
      }
    },
    [sendRequest, errorMessage]
  );

  // Get all productgroups on mount
  useEffect(() => {
    if (isLoggedIn) {
      getProductgroups();
    }
  }, [getProductgroups, isLoggedIn]);

  const addProductgroup = useCallback(
    async (productgroupToAdd: ProductgroupFormDto, onSuccess?: () => void) => {
      setAddProductgroupLoading(true);
      try {
        await sendRequest({
          url: 'productgroups',
          method: 'POST',
          body: JSON.stringify(productgroupToAdd),
        });
        notify({ message: t('added') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'productgroups',
          error,
          defaultTranslation: 'add',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
          ],
        });
      } finally {
        setAddProductgroupLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const editProductgroup = useCallback(
    async (
      id: number,
      productgroupToEdit: ProductgroupFormDto,
      onSuccess?: () => void
    ) => {
      setEditProductgroupLoading(true);
      try {
        await sendRequest({
          url: `productgroups/${id}`,
          method: 'PUT',
          body: JSON.stringify(productgroupToEdit),
        });
        notify({ message: t('edited') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'productgroups',
          error,
          defaultTranslation: 'edit',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
          ],
        });
      } finally {
        setEditProductgroupLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const deleteProductgroup = useCallback(
    async (id: number, onSuccess?: () => void) => {
      setDeleteProductgroupLoading(true);
      try {
        await sendRequest({
          url: `productgroups/${id}`,
          method: 'DELETE',
        });
        notify({ message: t('deleted') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'productgroups',
          error,
          defaultTranslation: 'delete',
        });
      } finally {
        setDeleteProductgroupLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  return (
    <ProductgroupContext.Provider
      value={{
        productgroups,
        getProductgroupsLoading,
        getProductgroups,
        addProductgroupLoading,
        addProductgroup,
        editProductgroup,
        editProductgroupLoading,
        deleteProductgroup,
        deleteProductgroupLoading,
      }}
    >
      {children}
    </ProductgroupContext.Provider>
  );
};

export { ProductgroupProvider, ProductgroupContext };
