import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SiteContext } from '../store/siteContext';
import { StorageContext } from '../store/storageContext';

export interface AutoCompleteOption {
    id: number;
    label: string;
    category: string;
}
interface Props {
    onSiteChangedCb: (result?: AutoCompleteOption) => void;
    onStorageChangedCb: (result?: AutoCompleteOption) => void;
}
const ReportSearch: React.FC<Props> = ({ onSiteChangedCb, onStorageChangedCb }) => {
    const { t } = useTranslation('reports');
    const { getStorages, getStorageListBySite, storages: allAvailableStorages } = React.useContext(StorageContext);
    const [storages, setStorages] = React.useState<StorageType[]>([]);
    const { getSites, sites } = React.useContext(SiteContext);
    const [selectedOptions, setSelectedOptions] = React.useState<{ site?: AutoCompleteOption; storage?: AutoCompleteOption; }>();

    React.useEffect(() => {
        getStorages();
    }, [getStorages]);
    React.useEffect(() => {
        getSites();
    }, [getSites]);
    React.useEffect(() => {
        if (selectedOptions?.site) {
            getStorageListBySite(selectedOptions.site.id, setStorages);
        } else {
            setStorages(allAvailableStorages);
        }
    }, [getStorageListBySite, selectedOptions, allAvailableStorages]);

    const handleSitesChanged = (v: AutoCompleteOption) => {
        setSelectedOptions({ site: v });
        onStorageChangedCb(undefined);
        onSiteChangedCb(v);
    };
    const handleStoragesChanged = (v: AutoCompleteOption) => {
        setSelectedOptions(prevOptions => ({ ...prevOptions, storage: v }));
        onStorageChangedCb(v);
    };

    return <div style={{ display: 'flex' }}>
        <Autocomplete
            id="combo-box"
            options={sites.map(i => ({ id: i.id, label: i.name, category: t("site") }))}
            style={{ width: '13em', paddingRight: '3px' }}
            onChange={(_, v) => handleSitesChanged(v as AutoCompleteOption)}
            getOptionLabel={i => i.label}
            getOptionSelected={(option, value) => option.id === value.id}
            /* eslint-disable react/jsx-props-no-spreading */
            renderInput={(params) => <TextField {...params} label={t("site")} variant="outlined" />}
        />
        <Autocomplete
            id="combo-box"
            value={selectedOptions?.storage || null}
            options={storages.map(i => ({ id: i.id, label: i.name, category: t("storage") }))}
            style={{ width: '13em' }}
            onChange={(_, v) => handleStoragesChanged(v as AutoCompleteOption)}
            getOptionLabel={i => i.label}
            getOptionSelected={(option, value) => option.id === value.id}
            /* eslint-disable react/jsx-props-no-spreading */
            renderInput={(params) => <TextField {...params} label={t("storage")} variant="outlined" />}
        />
    </div>
}

export default ReportSearch;