import * as React from 'react';
import { useState, useContext } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ProductForm from './ProductForm';
import { ProductContext } from '../store/productContext';
import CardTitle from '../utils/components/CardTitle';
import ListCardActions from '../utils/components/ListCardActions';
import CardRow from '../utils/components/CardRow';
import useGetProductgroupName from '../utils/hooks/useGetProductgroupName';

type Props = {
  product: Product;
  loading: boolean;
};

const ProductCard: React.FC<Props> = ({ product, loading }: Props) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const {
    getProducts,
    editProduct,
    editProductLoading,
    deleteProduct,
    deleteProductLoading,
  } = useContext(ProductContext);

  const { t } = useTranslation('products');
  const { getProductgroupName } = useGetProductgroupName();

  const handleEditSubmit = (data: ProductFormDto) => {
    editProduct(product.id, data, () => {
      setEditing(false);
      getProducts(true);
    });
  };

  const handleEditOpen = () => {
    setEditing(true);
  };

  const handleEditClose = () => {
    setEditing(false);
  };

  const handleDeleteSubmit = () => {
    deleteProduct(product.id, () => {
      setDeleting(false);
      getProducts(true);
    });
  };

  const handleDeleteOpen = () => {
    setDeleting(true);
  };

  const handleDeleteClose = () => {
    setDeleting(false);
  };

  return (
    <Card>
      <CardTitle
        title={`${t('name')}: ${product.name}`}
        onEditClick={handleEditOpen}
        loading={loading}
      />
      <CardContent>
        <CardRow loading={loading} content={`${t('code')}: ${product.code}`} />
        <CardRow
          loading={loading}
          content={`${t('description')}: ${product.description}`}
        />
        <CardRow
          loading={loading}
          content={`${t('dimensions')}: ${product.dimensions}`}
        />
        <CardRow loading={loading} content={`${t('unit')}: ${product.unit}`} />
        <CardRow
          loading={loading}
          content={`${t('productgroup')}: ${getProductgroupName(
            product.productGroupId
          )}`}
        />
      </CardContent>
      <ListCardActions
        loading={loading}
        ns="products"
        editing={editing}
        onEditClose={handleEditClose}
        deleting={deleting}
        onDeleteClick={handleDeleteOpen}
        onDeleteClose={handleDeleteClose}
        deleteLoading={deleteProductLoading}
        onDeleteConfirm={handleDeleteSubmit}
        editingContent={
          <ProductForm
            onSubmit={handleEditSubmit}
            defaultValues={product}
            buttonText={t('common:edit')}
            loading={editProductLoading}
            cancelButton
            onCancel={handleEditClose}
          />
        }
      />
    </Card>
  );
};

export default ProductCard;
