import * as React from 'react';
import { useContext, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Theme, Typography, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { SiteContext } from '../../store/siteContext';

import useGetSiteName from '../hooks/useGetSiteName';

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}));

type Props = {
  onChange: (sites: number[]) => void;
  value: number[];
};

const SitesInput: React.FC<Props> = ({
  onChange: setSelectedSites,
  value: selectedSites,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('sites');
  const { getSiteName } = useGetSiteName();

  const { sites } = useContext(SiteContext);

  const siteIds = useMemo(() => sites.map((s) => s.id), [sites]);

  if (!sites || !sites.length) {
    return <Typography>{t('noSites')}</Typography>;
  }

  const handleChange = (newSites: number[]) => {
    setSelectedSites(newSites);
  };

  const handleDelete = (id: number) => {
    const newSites = selectedSites.filter((s) => s !== id);
    setSelectedSites(newSites);
  };

  return (
    <Autocomplete
      multiple
      id="site-selection"
      options={siteIds}
      value={selectedSites}
      onChange={(_e: unknown, newSites) => handleChange(newSites)}
      getOptionLabel={(site) => getSiteName(site)}
      fullWidth
      disableCloseOnSelect
      renderInput={(params) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <TextField {...params} label={t('select')} variant="outlined" />
      )}
      renderTags={(value: number[]) =>
        value.map((option: number) => {
          const s = sites.find((site) => site.id === option);
          if (s) {
            return (
              <Chip
                key={s.id}
                className={classes.chip}
                variant="outlined"
                label={s.name}
                onDelete={() => handleDelete(s.id)}
              />
            );
          }
          return null;
        })
      }
    />
  );
};

export default SitesInput;
