import * as React from 'react';
import { useContext, useMemo } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { ProductgroupContext } from '../../store/productgroupContext';

import useGetProductgroupName from '../hooks/useGetProductgroupName';

type Props = {
  onChange: (productgroup: number | null) => void;
  value: number | null;
  error: boolean;
  helperText: string | undefined;
};

const ProductgroupInput: React.FC<Props> = ({
  onChange: setSelectedProductgroup,
  value: selectedProductgroup,
  error,
  helperText,
}: Props) => {
  const { t } = useTranslation('productgroups');
  const { getProductgroupName } = useGetProductgroupName();

  const { productgroups } = useContext(ProductgroupContext);

  const productgroupIds = useMemo(() => productgroups.map((s) => s.id), [
    productgroups,
  ]);

  if (!productgroups || !productgroups.length) {
    return <Typography>{t('noProductgroups')}</Typography>;
  }

  const handleChange = (newProductgroup: number | null) => {
    setSelectedProductgroup(newProductgroup);
  };

  return (
    <Autocomplete
      id="productgroup-selection"
      options={productgroupIds}
      value={selectedProductgroup}
      onChange={(_e: unknown, newProductgroup) => handleChange(newProductgroup)}
      getOptionLabel={(productgroup) => getProductgroupName(productgroup)}
      fullWidth
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          error={error}
          helperText={helperText}
          label={t('selectSingle')}
          variant="outlined"
        />
      )}
    />
  );
};

export default ProductgroupInput;
