import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Theme } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../store/authContext';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(4),
    width: '80%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

type FormInputs = {
  username: string;
  password: string;
};

const LoginForm: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('auth');
  const { login, isLoggedIn } = useContext(AuthContext);
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm<FormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: FormInputs) => {
    setLoading(true);
    await login(data.username, data.password);
    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn && !loading) {
      history.push('/');
    }
  }, [history, isLoggedIn, loading]);

  const requiredMessage = t('validation:required');
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        inputRef={register({
          required: requiredMessage,
        })}
        variant="outlined"
        color="secondary"
        margin="normal"
        fullWidth
        id="username"
        label={t('username')}
        name="username"
        autoComplete="username"
        autoFocus
        error={!!errors.username}
        helperText={errors.username?.message}
      />
      <TextField
        inputRef={register({ required: requiredMessage })}
        variant="outlined"
        color="secondary"
        margin="normal"
        fullWidth
        name="password"
        label={t('password')}
        type="password"
        id="password"
        autoComplete="current-password"
        error={!!errors.password}
        helperText={errors.password?.message}
      />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        classes={{ root: classes.button }}
        disabled={loading}
      >
        {t('login')}
      </Button>
    </form>
  );
};

export default LoginForm;
