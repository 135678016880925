import * as React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  onConfirm: () => void;
  ns: string;
}

const DeleteDialog: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  loading,
  ns,
}: Props) => {
  const { t } = useTranslation(ns);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('deleteConfirm')}</DialogTitle>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <Button color="primary" onClick={onConfirm} disabled={loading}>
          {t('common:delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
