import * as React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/hooks/useErrorMessage';

import UseHttpClient from '../utils/hooks/useHttpClient';
import useNotify from '../utils/hooks/useNotify';
import { AuthContext } from './authContext';

type ApplicationLinkContext = {
  applicationLinks: ApplicationLink[];
  getApplicationLinksLoading: boolean;
  addApplicationLinkLoading: boolean;
  editApplicationLinkLoading: boolean;
  deleteApplicationLinkLoading: boolean;
  getApplicationLinks: (skipLoading?: boolean) => void;
  addApplicationLink: (
    applicationLinkToAdd: ApplicationLinkFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  editApplicationLink: (
    id: number,
    applicationLinkToEdit: ApplicationLinkFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  deleteApplicationLink: (id: number, onSuccess?: () => void) => Promise<void>;
};

const ApplicationLinkContext = createContext<ApplicationLinkContext>({
  applicationLinks: [],
  getApplicationLinksLoading: false,
  addApplicationLinkLoading: false,
  editApplicationLinkLoading: false,
  deleteApplicationLinkLoading: false,
  getApplicationLinks: () => null,

  editApplicationLink: () => Promise.reject(),
  deleteApplicationLink: () => Promise.reject(),
  addApplicationLink: () => Promise.reject(),
});

const ApplicationLinkProvider: React.FC = ({ children }) => {
  const [applicationLinks, setApplicationLinks] = useState<ApplicationLink[]>(
    []
  );
  const [getApplicationLinksLoading, setGetApplicationLinksLoading] = useState(
    false
  );
  const [addApplicationLinkLoading, setAddApplicationLinkLoading] = useState(
    false
  );
  const [editApplicationLinkLoading, setEditApplicationLinkLoading] = useState(
    false
  );
  const [
    deleteApplicationLinkLoading,
    setDeleteApplicationLinkLoading,
  ] = useState(false);
  const { sendRequest } = UseHttpClient();
  const { t } = useTranslation('applicationLinks');
  const { errorMessage } = useErrorMessage();
  const { isLoggedIn } = useContext(AuthContext);

  const notify = useNotify();

  const getApplicationLinks = useCallback(
    async (skipLoading = false) => {
      if (!skipLoading) {
        setGetApplicationLinksLoading(true);
      }
      try {
        const resData = await sendRequest<ApplicationLink[]>({
          url: 'applicationlinks',
        });
        if (resData && resData.length) {
          setApplicationLinks(resData);
        }
      } catch (error) {
        errorMessage({
          ns: 'applicationlinks',
          error,
          defaultTranslation: 'list',
        });
      } finally {
        if (!skipLoading) {
          setGetApplicationLinksLoading(false);
        }
      }
    },
    [sendRequest, errorMessage]
  );

  // Get all application links on mount
  useEffect(() => {
    if (isLoggedIn) {
      getApplicationLinks();
    }
  }, [getApplicationLinks, isLoggedIn]);

  const addApplicationLink = useCallback(
    async (
      applicationLinkToAdd: ApplicationLinkFormDto,
      onSuccess?: () => void
    ) => {
      setAddApplicationLinkLoading(true);
      try {
        await sendRequest({
          url: 'applicationlinks',
          method: 'POST',
          body: JSON.stringify(applicationLinkToAdd),
        });
        notify({ message: t('added') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'applicationLinks',
          error,
          defaultTranslation: 'add',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
          ],
        });
      } finally {
        setAddApplicationLinkLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const editApplicationLink = useCallback(
    async (
      id: number,
      applicationLinkToEdit: ApplicationLinkFormDto,
      onSuccess?: () => void
    ) => {
      setEditApplicationLinkLoading(true);
      try {
        await sendRequest({
          url: `applicationlinks/${id}`,
          method: 'PUT',
          body: JSON.stringify(applicationLinkToEdit),
        });
        notify({ message: t('edited') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'applicationLinks',
          error,
          defaultTranslation: 'edit',
        });
      } finally {
        setEditApplicationLinkLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const deleteApplicationLink = useCallback(
    async (id: number, onSuccess?: () => void) => {
      setDeleteApplicationLinkLoading(true);
      try {
        await sendRequest({
          url: `applicationlinks/${id}`,
          method: 'DELETE',
        });
        notify({ message: t('deleted') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'applicationLinks',
          error,
          defaultTranslation: 'delete',
        });
      } finally {
        setDeleteApplicationLinkLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  return (
    <ApplicationLinkContext.Provider
      value={{
        applicationLinks,
        getApplicationLinksLoading,
        getApplicationLinks,
        addApplicationLinkLoading,
        addApplicationLink,
        editApplicationLinkLoading,
        editApplicationLink,
        deleteApplicationLinkLoading,
        deleteApplicationLink,
      }}
    >
      {children}
    </ApplicationLinkContext.Provider>
  );
};

export { ApplicationLinkProvider, ApplicationLinkContext };
