import * as React from 'react';
import { TableCell, TableCellProps } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

interface Props {
  first?: boolean;
  loading: boolean;
  children: React.ReactNode;
  align?: TableCellProps['align'];
}

const DataCell: React.FC<Props> = ({
  first = false,
  loading,
  children,
  align = 'left',
}: Props) => {
  return (
    <TableCell
      component={first ? 'th' : undefined}
      scope={first ? 'row' : undefined}
      align={align}
    >
      {loading ? (
        <Skeleton>
          <span>{children}</span>
        </Skeleton>
      ) : (
        children
      )}
    </TableCell>
  );
};

export default DataCell;
