const loadingProductgroups: Productgroup[] = [
  {
    id: 0,
    name: 'Test productgroup',
  },
  {
    id: 1,
    name: 'Test productgroup 2',
  },
];

export default loadingProductgroups;
