import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useNotify from './useNotify';

type ErrorMessageArgs = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: Error | any;
  defaultTranslation: string;
  cases?: { text: string; translation: string }[];
  ns: string;
};

export default function useErrorMessage(): {
  errorMessage: ({
    error,
    defaultTranslation,
    cases,
    ns,
  }: ErrorMessageArgs) => void;
} {
  const { t } = useTranslation();
  const notify = useNotify();

  const errorMessage = useCallback(
    ({ error, defaultTranslation, cases, ns }: ErrorMessageArgs) => {
      let errMsg = error?.message || error;
      let translate = defaultTranslation;
      let useCommonNs = false;
      if (typeof errMsg === 'string') {
        errMsg = errMsg.toLowerCase();
        // Forbidden case is common for all queries
        if (errMsg.includes('access denied') || errMsg.includes('forbidden')) {
          translate = 'forbidden';
          useCommonNs = true;
        }
        if (cases && cases.length) {
          cases.forEach((c) => {
            if (errMsg.includes(c.text)) {
              translate = c.translation;
              useCommonNs = false;
            }
          });
        }
      }
      notify({
        message: t(`${useCommonNs ? 'common' : ns}:error.${translate}`),
        type: 'error',
      });
    },
    [t, notify]
  );

  return { errorMessage };
}
