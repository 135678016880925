import * as React from 'react';
import { useState, useContext } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ProductgroupForm from './ProductgroupForm';
import { ProductgroupContext } from '../store/productgroupContext';
import CardTitle from '../utils/components/CardTitle';
import ListCardActions from '../utils/components/ListCardActions';
// import CardRow from '../utils/components/CardRow';

type Props = {
  productgroup: Productgroup;
  loading: boolean;
};

const ProductgroupCard: React.FC<Props> = ({
  productgroup,
  loading,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const {
    getProductgroups,
    editProductgroup,
    editProductgroupLoading,
    deleteProductgroup,
    deleteProductgroupLoading,
  } = useContext(ProductgroupContext);

  const { t } = useTranslation('productgroups');

  const handleEditSubmit = (data: ProductgroupFormDto) => {
    editProductgroup(productgroup.id, data, () => {
      setEditing(false);
      getProductgroups(true);
    });
  };

  const handleEditOpen = () => {
    setEditing(true);
  };

  const handleEditClose = () => {
    setEditing(false);
  };

  const handleDeleteSubmit = () => {
    deleteProductgroup(productgroup.id, () => {
      setDeleting(false);
      getProductgroups(true);
    });
  };

  const handleDeleteOpen = () => {
    setDeleting(true);
  };

  const handleDeleteClose = () => {
    setDeleting(false);
  };

  return (
    <Card>
      <CardTitle
        title={`${t('name')}: ${productgroup.name}`}
        onEditClick={handleEditOpen}
        loading={loading}
      />
      <CardContent />
      <ListCardActions
        loading={loading}
        ns="productgroups"
        editing={editing}
        onEditClose={handleEditClose}
        deleting={deleting}
        onDeleteClick={handleDeleteOpen}
        onDeleteClose={handleDeleteClose}
        deleteLoading={deleteProductgroupLoading}
        onDeleteConfirm={handleDeleteSubmit}
        editingContent={
          <ProductgroupForm
            onSubmit={handleEditSubmit}
            defaultValues={productgroup}
            buttonText={t('common:edit')}
            loading={editProductgroupLoading}
            cancelButton
            onCancel={handleEditClose}
          />
        }
      />
    </Card>
  );
};

export default ProductgroupCard;
