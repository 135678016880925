import * as React from 'react';
import { Chip, Tooltip } from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useTranslation } from 'react-i18next';
import useGetContactInfo from '../hooks/useGetContactInfo';

type Props = {
  contactId: number;
};

const ContactChip: React.FC<Props> = ({ contactId }: Props) => {
  const { t } = useTranslation('contacts');
  const { getContactInfo } = useGetContactInfo();

  const { email, type } = getContactInfo(contactId);
  let icon = <ContactSupportIcon />;
  if (type === 'ORDER') {
    icon = <ReceiptIcon />;
  } else if (type === 'REMINDER') {
    icon = <NotificationsIcon />;
  }
  const title = t(type ? type.toLowerCase() : '');
  return (
    <Tooltip title={title}>
      <Chip icon={icon} variant="outlined" label={email} color="secondary" />
    </Tooltip>
  );
};

export default ContactChip;
