const loadingUsers: User[] = [
  {
    id: 0,
    name: 'Test User',
    email: 'test.user@testuser.test',
    role: 'ADMIN',
    locale: 'FI',
    siteIds: [0, 1, 2],
  },
  {
    id: 1,
    name: 'Test User 2',
    email: 'test.user2@testuser.test',
    role: 'STOCKING',
    locale: 'EN',
    siteIds: [0, 1, 2],
  },
];

export default loadingUsers;
