import * as React from 'react';
import { useState, useContext } from 'react';
import { Grid, Fab, Dialog, Zoom, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

import SiteCard from './SiteCard';
import { SiteContext } from '../store/siteContext';
import SiteForm from './SiteForm';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  lastCard: {
    marginBottom: theme.spacing(10),
  },
}));

type Props = {
  sites: Site[];
  loading: boolean;
};

const SiteListMobile: React.FC<Props> = ({ sites, loading }: Props) => {
  const classes = useStyles();
  const [adding, setAdding] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const { addSite, getSites, addSiteLoading } = useContext(SiteContext);

  const handleAddSubmit = (data: SiteFormDto) => {
    addSite(data, () => {
      setAdding(false);
      getSites(true);
    });
  };

  const handleAddOpen = () => {
    setAdding(true);
  };

  const handleAddClose = () => {
    setAdding(false);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Grid container spacing={3}>
      {sites.map((s, index) => (
        <Grid
          key={s.id}
          item
          xs={12}
          className={index === sites.length - 1 ? classes.lastCard : ''}
        >
          <SiteCard site={s} loading={loading} />
        </Grid>
      ))}
      <Zoom in timeout={transitionDuration} unmountOnExit>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={handleAddOpen}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <Dialog open={adding} onClose={handleAddClose}>
        <SiteForm
          onSubmit={handleAddSubmit}
          buttonText={t('common:add')}
          loading={addSiteLoading}
          cancelButton
          onCancel={handleAddClose}
        />
      </Dialog>
    </Grid>
  );
};

export default SiteListMobile;
