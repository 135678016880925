import * as React from 'react';
import { useState, useContext } from 'react';
import { Chip, Grid, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useGetSiteName from '../utils/hooks/useGetSiteName';
import UserForm from './UserForm';
import { UserContext } from '../store/userContext';
import DataCell from '../utils/components/DataCell';
import LoadingData from '../utils/components/LoadingData';
import ActionCell from '../utils/components/ActionCell';
import DeleteDialog from '../utils/components/DeleteDialog';

type Props = {
  user: User;
  loading: boolean;
  colCount: number;
};

const UserRow: React.FC<Props> = ({ user, loading, colCount }: Props) => {
  const [editing, setEditing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation('users');
  const { getSiteName } = useGetSiteName();
  const {
    getUsers,
    editUser,
    editUserLoading,
    deleteUser,
    deleteUserLoading,
  } = useContext(UserContext);

  const handleEditToggle = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  const handleSubmit = (data: UserFormDto) => {
    editUser(user.id, data, () => {
      setEditing(false);
      getUsers(true);
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    deleteUser(user.id, () => {
      handleDialogClose();
      getUsers(true);
    });
  };

  return (
    <>
      <TableRow hover>
        <DataCell first loading={loading}>
          {user.name}
        </DataCell>
        <DataCell loading={loading}>{user.email}</DataCell>
        <DataCell loading={loading}>{t(user.role.toLowerCase())}</DataCell>
        <DataCell loading={loading}>{user.locale}</DataCell>
        <DataCell loading={false}>
          <Grid container spacing={1}>
            {user.siteIds?.map((s) => (
              <Grid key={s} item>
                <LoadingData loading={loading}>
                  <Chip
                    variant="outlined"
                    label={getSiteName(s)}
                    color="secondary"
                  />
                </LoadingData>
              </Grid>
            ))}
          </Grid>
        </DataCell>
        <ActionCell
          editing={editing}
          onEditClick={handleEditToggle}
          onDeleteClick={handleDeleteConfirmation}
          loading={loading}
          showDelete
        />
      </TableRow>
      {editing && (
        <TableRow>
          <TableCell colSpan={colCount}>
            <UserForm
              onSubmit={handleSubmit}
              defaultValues={user}
              buttonText={t('common:edit')}
              loading={editUserLoading}
            />
          </TableCell>
        </TableRow>
      )}
      <DeleteDialog
        loading={deleteUserLoading}
        open={dialogOpen}
        onClose={handleDialogClose}
        ns="users"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default UserRow;
