import * as React from 'react';
import { useState, useContext } from 'react';
import { Card, CardContent } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useGetSiteName from '../utils/hooks/useGetSiteName';
import StorageForm from './StorageForm';
import { StorageContext } from '../store/storageContext';
import CardTitle from '../utils/components/CardTitle';
import ListCardActions from '../utils/components/ListCardActions';
import CardRow from '../utils/components/CardRow';

type Props = {
  storage: StorageType;
  loading: boolean;
};

const StorageCard: React.FC<Props> = ({ storage, loading }: Props) => {
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const {
    getStorages,
    editStorage,
    editStorageLoading,
    deleteStorage,
    deleteStorageLoading,
  } = useContext(StorageContext);

  const { t } = useTranslation('storages');
  const { getSiteName } = useGetSiteName();

  const handleEditSubmit = (data: StorageFormDto) => {
    editStorage(storage.id, data, () => {
      setEditing(false);
      getStorages(true);
    });
  };

  const handleEditOpen = () => {
    setEditing(true);
  };

  const handleEditClose = () => {
    setEditing(false);
  };

  const handleDeleteSubmit = () => {
    deleteStorage(storage.id, () => {
      setDeleting(false);
      getStorages(true);
    });
  };

  const handleDeleteOpen = () => {
    setDeleting(true);
  };

  const handleDeleteClose = () => {
    setDeleting(false);
  };

  const reportDate = storage.lastReportDate
    ? new Date(storage.lastReportDate).toLocaleDateString()
    : '';

  const inventoryDate = storage.lastInventoryDate
    ? new Date(storage.lastInventoryDate).toLocaleDateString()
    : '';

  return (
    <Card>
      <CardTitle
        title={`${t('name')}: ${storage.name}`}
        onEditClick={handleEditOpen}
        loading={loading}
      />
      <CardContent>
        <CardRow
          loading={loading}
          content={`${t('lastReportDate')}: ${reportDate}`}
        />
        <CardRow
          loading={loading}
          content={`${t('lastInventoryDate')}: ${inventoryDate}`}
        />
        <CardRow
          loading={loading}
          content={`${t('site')}: ${getSiteName(storage.siteId)}`}
        />
      </CardContent>
      <ListCardActions
        loading={loading}
        ns="storages"
        editing={editing}
        onEditClose={handleEditClose}
        deleting={deleting}
        onDeleteClick={handleDeleteOpen}
        onDeleteClose={handleDeleteClose}
        deleteLoading={deleteStorageLoading}
        onDeleteConfirm={handleDeleteSubmit}
        editingContent={
          <StorageForm
            onSubmit={handleEditSubmit}
            defaultValues={storage}
            buttonText={t('common:edit')}
            loading={editStorageLoading}
            cancelButton
            onCancel={handleEditClose}
          />
        }
      />
    </Card>
  );
};

export default StorageCard;
