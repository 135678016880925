const loadingContacts: Report[] = [
  {
    id: 0,
    date: new Date(1,7,2021),
    siteName: "Site 1",
    storageName: "storage 1",
    reporterName: "reporter 5",
    inventory: true,
    imageCount:0,
    inventoryLogs: [
      {
        amountAdded:2,
        amountMissing: 0,
        productCode: "prod-23"
      },
      {
        amountAdded:21,
        amountMissing: 3,
        productCode: "prod-20"
      },
    ],
    stockingLogs: []
  },
  
  {
    id: 1,
    date: new Date(10,8,2021),
    siteName: "Site 3",
    storageName: "storage 1",
    reporterName: "reporter 15",
    inventory: false,
    imageCount:0,
    inventoryLogs: [],
    stockingLogs: [
    {
        amountAdded:2,
        amountMissing: 0,
        productCode: "prod-23"
      },
      {
        amountAdded:21,
        amountMissing: 3,
        productCode: "prod-20"
    }
    ]
  }
  
];

export default loadingContacts;
