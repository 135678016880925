import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Theme, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { AuthContext } from '../store/authContext';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  text: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(4),
    width: '80%',
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  helperText: {
    width: '100%',
  },
}));

type FormInputs = {
  password: string;
  repeatPassword: string;
};

const ChangePasswordForm: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation('auth');
  const { completeNewPassword, isLoggedIn } = useContext(AuthContext);
  const history = useHistory();

  const { register, handleSubmit, errors, watch } = useForm<FormInputs>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: FormInputs) => {
    setLoading(true);
    await completeNewPassword(data.password);
    setLoading(true);
  };

  useEffect(() => {
    if (isLoggedIn && !loading) {
      history.push('/');
    }
  }, [history, isLoggedIn, loading]);

  const requiredMessage = t('validation:required');
  const passwordMismatchMessage = t('validation:passwordMismatch');
  const passwordReqMessage = t('validation:passwordReq');
  return (
    <>
      <Typography align="center" color="error" className={classes.text}>
        {t('changePasswordMessage')}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          inputRef={register({
            required: requiredMessage,
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/,
              message: passwordReqMessage,
            },
          })}
          autoFocus
          variant="outlined"
          color="secondary"
          margin="normal"
          fullWidth
          name="password"
          label={t('password')}
          type="password"
          id="password"
          autoComplete="current-password"
          error={!!errors.password}
          helperText={errors.password?.message}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
        />
        <TextField
          inputRef={register({
            required: requiredMessage,
            validate: (value) =>
              value === watch('password') || passwordMismatchMessage,
          })}
          variant="outlined"
          color="secondary"
          margin="normal"
          fullWidth
          name="repeatPassword"
          label={t('repeatPassword')}
          type="password"
          id="repeat-password"
          error={!!errors.repeatPassword}
          helperText={errors.repeatPassword?.message}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          classes={{ root: classes.button }}
          disabled={loading}
        >
          {t('changePassword')}
        </Button>
      </form>
    </>
  );
};

export default ChangePasswordForm;
