import * as React from 'react';
import { useState, useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ProductForm from './ProductForm';
import { ProductContext } from '../store/productContext';
import DataCell from '../utils/components/DataCell';
import ActionCell from '../utils/components/ActionCell';
import DeleteDialog from '../utils/components/DeleteDialog';
import useGetProductgroupName from '../utils/hooks/useGetProductgroupName';

type Props = {
  product: Product;
  loading: boolean;
  colCount: number;
};

const ProductRow: React.FC<Props> = ({ product, loading, colCount }: Props) => {
  const [editing, setEditing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation('products');
  const { getProductgroupName } = useGetProductgroupName();
  const {
    getProducts,
    editProduct,
    editProductLoading,
    deleteProduct,
    deleteProductLoading,
  } = useContext(ProductContext);

  const handleEditToggle = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  const handleSubmit = (data: ProductFormDto) => {
    editProduct(product.id, data, () => {
      setEditing(false);
      getProducts(true);
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    deleteProduct(product.id, () => {
      handleDialogClose();
      getProducts(true);
    });
  };

  return (
    <>
      <TableRow hover>
        <DataCell first loading={loading}>
          {product.name}
        </DataCell>
        <DataCell loading={loading}>{product.code}</DataCell>
        <DataCell loading={loading}>{product.description}</DataCell>
        <DataCell loading={loading}>{product.dimensions}</DataCell>
        <DataCell loading={loading}>{product.unit}</DataCell>
        <DataCell loading={loading}>
          {getProductgroupName(product.productGroupId)}
        </DataCell>
        <ActionCell
          editing={editing}
          onEditClick={handleEditToggle}
          onDeleteClick={handleDeleteConfirmation}
          loading={loading}
          showDelete
        />
      </TableRow>
      {editing && (
        <TableRow>
          <TableCell colSpan={colCount}>
            <ProductForm
              onSubmit={handleSubmit}
              defaultValues={product}
              buttonText={t('common:edit')}
              loading={editProductLoading}
            />
          </TableCell>
        </TableRow>
      )}
      <DeleteDialog
        loading={deleteProductLoading}
        open={dialogOpen}
        onClose={handleDialogClose}
        ns="products"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default ProductRow;
