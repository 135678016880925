import * as React from 'react';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';

interface Props {
  children: React.ReactElement;
}

function HideOnScroll(props: Props): React.ReactElement {
  const { children } = props;
  const target = document.getElementById('main-content') || window;
  const trigger = useScrollTrigger({ target, threshold: 50 });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default HideOnScroll;
