import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 'auto',
    overflow: 'auto',
    flexGrow: 1,
  },
  activeLink: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
  textContainer: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  text: {
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.body2.fontSize,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.75rem',
    },
  },
  listItemGutters: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0.25),
      paddingRight: theme.spacing(0.25),
    },
  },
}));

type Props = {
  to: string;
  name: string;
  exact?: boolean;
};

const NavItem: React.FC<Props> = ({ to, name, exact = false }: Props) => {
  const classes = useStyles();

  return (
    <ListItem
      button
      component={NavLink}
      activeClassName={classes.activeLink}
      classes={{
        gutters: classes.listItemGutters,
        root: classes.root,
      }}
      to={to}
      exact={exact}
    >
      <ListItemText
        className={classes.textContainer}
        primaryTypographyProps={{ noWrap: true, className: classes.text }}
        primary={name}
      />
    </ListItem>
  );
};

export default NavItem;
