import * as React from 'react';
import { Typography, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  pos: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    wordBreak: 'break-word',
  },
}));

interface Props {
  loading: boolean;
  content: React.ReactNode;
}

const CardRow: React.FC<Props> = ({ loading, content }: Props) => {
  const classes = useStyles();
  return (
    <>
      {loading ? (
        <Skeleton>
          <Typography className={classes.pos} color="textPrimary" gutterBottom>
            {content}
          </Typography>
        </Skeleton>
      ) : (
        <Typography className={classes.pos} color="textPrimary" gutterBottom>
          {content}
        </Typography>
      )}
    </>
  );
};

export default CardRow;
