import * as React from 'react';
import { createContext, useState, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useTranslation } from 'react-i18next';

import { CognitoUserExt } from '../login/cognitoUser';
import useNotify from '../utils/hooks/useNotify';
import useErrorMessage from '../utils/hooks/useErrorMessage';

type AuthContext = {
  isLoggedIn: boolean | undefined;
  login: (username: string, password: string) => Promise<void>;
  logout: (callback?: () => void) => void;
  refreshSession: () => Promise<string>;
  completeNewPassword: (password: string) => Promise<void>;
  user: CognitoUserExt | undefined;
  userSession: CognitoUserSession | null;
  token: string;
};

const AuthContext = createContext<AuthContext>({
  isLoggedIn: undefined,
  login: () => Promise.reject(),
  logout: () => null,
  refreshSession: () => Promise.reject(),
  completeNewPassword: () => Promise.reject(),
  user: undefined,
  userSession: null,
  token: '',
});

const AuthProvider: React.FC = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [user, setUser] = useState<CognitoUserExt>();
  const [token, setToken] = useState('');
  const [userSession, setUserSession] = useState<CognitoUserSession | null>(
    null
  );
  const notify = useNotify();

  const { t: _t } = useTranslation('auth');
  const { errorMessage } = useErrorMessage();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  // Set session data etc
  const afterLogin = useCallback((res: CognitoUserExt) => {
    res?.getSession(
      (_error: Error | null, session: CognitoUserSession | null) => {
        setUserSession(session);
        setToken(session?.getIdToken().getJwtToken() || '');
      }
    );
  }, []);

  // On mount, check if user data exists locally then autologin
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        if (res) {
          setIsLoggedIn(true);
          setUser(res);
          afterLogin(res);
        }
      })
      .catch(() => {
        setIsLoggedIn(false);
        history.push('/login');
      });
  }, [afterLogin, history]);

  // Refresh current session
  const refreshSession = useCallback(async () => {
    try {
      const u: CognitoUserSession = await Auth.currentSession();
      const newToken = u.getIdToken().getJwtToken() || '';
      setToken(newToken);
      setUserSession(u);
      return newToken;
    } catch {
      return '';
    }
  }, []);

  // Refresh session when location changes
  useEffect(() => {
    refreshSession();
  }, [refreshSession, pathname]);

  // Login
  const login = useCallback(
    async (username: string, password: string) => {
      try {
        const res: CognitoUserExt = await Auth.signIn(username, password);
        // If no challenges -> logged in, otherwise the completeNewPassword
        // form will be shown on /login
        if (!res?.challengeName) {
          setIsLoggedIn(true);
          setUser(res);
          afterLogin(res);
        } else {
          setUser(res);
        }
      } catch (error) {
        errorMessage({
          ns: 'auth',
          error,
          defaultTranslation: 'generic',
          cases: [
            {
              text: 'does not exist',
              translation: 'notFound',
            },
            {
              text: 'incorrect username or password',
              translation: 'incorrect',
            },
          ],
        });
      }
    },
    [afterLogin, errorMessage]
  );

  // Complete new password
  const completeNewPassword = useCallback(
    async (password: string) => {
      try {
        const res: CognitoUserExt = await Auth.completeNewPassword(
          user,
          password
        );
        setIsLoggedIn(true);
        setUser(res);
        afterLogin(res);
      } catch (err) {
        notify({
          message: err?.message || err,
          type: 'error',
        });
      }
    },
    [user, afterLogin, notify]
  );

  // Logout
  const logout = useCallback(
    (cb?: () => void) => {
      try {
        user?.signOut();
        setIsLoggedIn(false);
        if (cb) {
          cb();
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}
    },
    [user]
  );

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        login,
        logout,
        refreshSession,
        user,
        userSession,
        completeNewPassword,
        token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
