import * as React from 'react';
import { useState, useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useGetSiteName from '../utils/hooks/useGetSiteName';
import ContactForm from './ContactForm';
import { ContactContext } from '../store/contactContext';
import DataCell from '../utils/components/DataCell';
import ActionCell from '../utils/components/ActionCell';
import DeleteDialog from '../utils/components/DeleteDialog';

type Props = {
  contact: Contact;
  loading: boolean;
  colCount: number;
};

const ContactRow: React.FC<Props> = ({ contact, loading, colCount }: Props) => {
  const [editing, setEditing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation('contacts');
  const { getSiteName } = useGetSiteName();
  const {
    getContacts,
    editContact,
    editContactLoading,
    deleteContact,
    deleteContactLoading,
  } = useContext(ContactContext);

  const handleEditToggle = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  const handleSubmit = (data: ContactFormDto) => {
    editContact(contact.id, data, () => {
      setEditing(false);
      getContacts(true);
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    deleteContact(contact.id, () => {
      handleDialogClose();
      getContacts(true);
    });
  };

  return (
    <>
      <TableRow hover>
        <DataCell first loading={loading}>
          {contact.email}
        </DataCell>
        <DataCell loading={loading}>
          {t(contact.contactType.toLowerCase())}
        </DataCell>
        <DataCell loading={loading}>{contact.locale}</DataCell>
        <DataCell loading={loading}>{getSiteName(contact.siteId)}</DataCell>
        <ActionCell
          editing={editing}
          onEditClick={handleEditToggle}
          onDeleteClick={handleDeleteConfirmation}
          loading={loading}
          showDelete
        />
      </TableRow>
      {editing && (
        <TableRow>
          <TableCell colSpan={colCount}>
            <ContactForm
              onSubmit={handleSubmit}
              defaultValues={contact}
              buttonText={t('common:edit')}
              loading={editContactLoading}
            />
          </TableCell>
        </TableRow>
      )}
      <DeleteDialog
        loading={deleteContactLoading}
        open={dialogOpen}
        onClose={handleDialogClose}
        ns="contacts"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default ContactRow;
