const loadingApplicationLinks: ApplicationLink[] = [
  {
    id: 0,
    linkText: "link1",
    linkUrl: "htpps://www.google.fi",
    hidden: false,
    lastUpdated: new Date(),
    ordering: 11,
  },
  {
    id: 1,
    linkText: "link2",
    linkUrl: "htpps://www.google.fi",
    hidden: false,
    lastUpdated: new Date(),
    ordering: 10,
  },
];

export default loadingApplicationLinks;
