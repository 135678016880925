import { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../store/userContext';

export default function useGetUserName(): {
  getUserName: (id: number | null) => string;
} {
  const { t } = useTranslation('users');
  const { users } = useContext(UserContext);

  const getUserName = useCallback(
    (id: number | null) => {
      if (id === null) {
        return '';
      }
      const user = users.find((s) => s.id === id);
      if (user && user.name) {
        return user.name;
      }
      return t('unknown');
    },
    [users, t]
  );

  return { getUserName };
}
