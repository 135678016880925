import * as React from 'react';
import { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Theme, Typography } from '@material-ui/core';

import valmetLogo from '../assets/valmet_logo@3x.png';
import LoginForm from './LoginForm';
import ChangePasswordForm from './ChangePasswordForm';
import { AuthContext } from '../store/authContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '5rem',
  },
  logo: {
    height: '5rem',
  },
  title: {
    color: theme.palette.secondary.main,
    marginTop: -16,
  },
  form: {
    maxWidth: 438,
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.container}
    >
      <Grid item>
        <img src={valmetLogo} alt="Valmet logo" className={classes.logo} />
      </Grid>
      <Grid item>
        <Typography className={classes.title}>
          Valmet Product Tracker
        </Typography>
      </Grid>
      <Grid item xl={8} md={6} xs={11} className={classes.form}>
        {user?.challengeName === 'NEW_PASSWORD_REQUIRED' ? (
          <ChangePasswordForm />
        ) : (
          <LoginForm />
        )}
      </Grid>
    </Grid>
  );
};

export default Login;
