import * as React from 'react';
import { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ProductgroupContext } from '../store/productgroupContext';
import ProductgroupListMobile from './ProductgroupListMobile';
import loadingProductgroups from './loadingProductgroups';
import DesktopList from '../utils/components/DesktopList';
import ProductgroupRow from './ProductgroupRow';
import ProductgroupForm from './ProductgroupForm';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
}));

const ProductgroupList: React.FC = () => {
  const classes = useStyles();
  const {
    getProductgroups,
    productgroups,
    getProductgroupsLoading,
    addProductgroup,
    addProductgroupLoading,
  } = useContext(ProductgroupContext);
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('productgroups');

  useEffect(() => {
    getProductgroups();
  }, [getProductgroups]);

  return (
    <Container maxWidth="lg">
      <Typography
        align="center"
        color="secondary"
        variant="h3"
        className={classes.title}
      >
        {t('productgroups')}
      </Typography>
      {isMediumOrSmaller ? (
        <ProductgroupListMobile
          productgroups={
            getProductgroupsLoading ? loadingProductgroups : productgroups
          }
          loading={getProductgroupsLoading}
        />
      ) : (
        <DesktopList<Productgroup, ProductgroupFormDto>
          headers={['name']}
          data={getProductgroupsLoading ? loadingProductgroups : productgroups}
          loading={getProductgroupsLoading}
          ns="productgroups"
          add={addProductgroup}
          get={getProductgroups}
          emptyKey="noProductgroups"
          renderAddForm={({ onSubmit, buttonText }) => (
            <ProductgroupForm
              loading={addProductgroupLoading}
              onSubmit={onSubmit}
              buttonText={buttonText}
            />
          )}
          renderBody={({ data, loading, columnCount }) => (
            <ProductgroupRow
              key={data.id}
              productgroup={data}
              loading={loading}
              colCount={columnCount}
            />
          )}
          showAdd
        />
      )}
    </Container>
  );
};

export default ProductgroupList;
