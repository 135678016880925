import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CardActions,
  IconButton,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  actions: {
    marginLeft: 'auto',
  },
}));

type Props = {
  loading: boolean;
  deleteLoading: boolean;
  ns: string;
  editing: boolean;
  deleting: boolean;
  editingContent: React.ReactNode;
  onEditClose: () => void;
  onDeleteConfirm: () => void;
  onDeleteClick: () => void;
  onDeleteClose: () => void;
};

const ListCardActions: React.FC<Props> = ({
  loading,
  deleteLoading,
  ns,
  editing,
  deleting,
  onDeleteClick,
  onDeleteClose,
  editingContent,
  onEditClose,
  onDeleteConfirm,
}: Props) => {
  const classes = useStyles();

  const { t } = useTranslation(ns);

  return (
    <CardActions>
      {loading ? (
        <Skeleton className={classes.actions}>
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Skeleton>
      ) : (
        <IconButton
          className={classes.actions}
          title={t('common:delete')}
          color="secondary"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <Dialog open={editing} onClose={onEditClose}>
        {editingContent}
      </Dialog>
      <Dialog open={deleting} onClose={onDeleteClose}>
        <DialogTitle>{t('deleteConfirm')}</DialogTitle>
        <DialogActions>
          <Button color="secondary" onClick={onDeleteClose}>
            {t('common:cancel')}
          </Button>
          <Button
            color="primary"
            onClick={onDeleteConfirm}
            disabled={deleteLoading}
          >
            {t('common:delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </CardActions>
  );
};

export default ListCardActions;
