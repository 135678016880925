import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField, Theme, Grid } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ProductgroupInput from '../utils/components/ProductgroupInput';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%',
    padding: theme.spacing(5),
  },
  formItem: {
    minHeight: 120,
    [theme.breakpoints.down('md')]: {
      minHeight: 'unset',
    },
  },
  button: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  chip: {
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}));

type Props = {
  onSubmit: (data: ProductFormDto) => void;
  onCancel?: () => void;
  cancelButton?: boolean;
  defaultValues?: ProductFormDto;
  buttonText: string;
  loading: boolean;
};

const ProductForm: React.FC<Props> = ({
  onSubmit,
  onCancel = () => null,
  defaultValues = {
    name: '',
    code: '',
    description: '',
    dimensions: '',
    unit: '',
    productGroupId: null,
  },
  buttonText,
  cancelButton = false,
  loading,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation('products');

  const { register, handleSubmit, errors, control } = useForm<ProductFormDto>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues,
  });

  const requiredMessage = t('validation:required');
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} justify="center" alignItems="center">
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
            })}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="name"
            label={t('name')}
            name="name"
            autoFocus
            error={!!errors.name}
            helperText={errors.name?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register({
              required: requiredMessage,
            })}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="code"
            label={t('code')}
            name="code"
            error={!!errors.code}
            helperText={errors.code?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register()}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="description"
            label={t('description')}
            name="description"
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register()}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="dimensions"
            label={t('dimensions')}
            name="dimensions"
            error={!!errors.dimensions}
            helperText={errors.dimensions?.message}
          />
        </Grid>
        <Grid item lg={4} xs={12} className={classes.formItem}>
          <TextField
            inputRef={register()}
            autoComplete="off"
            fullWidth
            variant="outlined"
            color="secondary"
            margin="normal"
            id="unit"
            label={t('unit')}
            name="unit"
            error={!!errors.unit}
            helperText={errors.unit?.message}
          />
        </Grid>
        <Grid item lg={12} xs={12}>
          <Controller
            name="productGroupId"
            control={control}
            render={({ onChange, value }) => (
              <ProductgroupInput
                value={value}
                onChange={onChange}
                error={!!errors.productGroupId}
                helperText={errors.productGroupId?.message}
              />
            )}
            defaultValue={null}
            rules={{ required: requiredMessage }}
          />
        </Grid>
        <Grid item container lg={6} xs={10} direction="row">
          {cancelButton && (
            <Button
              variant="contained"
              color="secondary"
              classes={{ root: classes.button }}
              onClick={onCancel}
            >
              {t('common:cancel')}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            classes={{ root: classes.button }}
            disabled={loading}
          >
            {buttonText}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProductForm;
