import * as React from 'react';
import { useState, useContext } from 'react';
import { Grid, Fab, Dialog, Zoom, useTheme } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';

import ProductgroupCard from './ProductgroupCard';
import { ProductgroupContext } from '../store/productgroupContext';
import ProductgroupForm from './ProductgroupForm';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'fixed',
    right: 20,
    bottom: 20,
  },
  lastCard: {
    marginBottom: theme.spacing(10),
  },
}));

type Props = {
  productgroups: Productgroup[];
  loading: boolean;
};

const ProductgroupListMobile: React.FC<Props> = ({
  productgroups,
  loading,
}: Props) => {
  const classes = useStyles();
  const [adding, setAdding] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    addProductgroup,
    getProductgroups,
    addProductgroupLoading,
  } = useContext(ProductgroupContext);

  const handleAddSubmit = (data: ProductgroupFormDto) => {
    addProductgroup(data, () => {
      setAdding(false);
      getProductgroups(true);
    });
  };

  const handleAddOpen = () => {
    setAdding(true);
  };

  const handleAddClose = () => {
    setAdding(false);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  return (
    <Grid container spacing={3}>
      {productgroups.map((p, index) => (
        <Grid
          key={p.id}
          item
          xs={12}
          className={index === productgroups.length - 1 ? classes.lastCard : ''}
        >
          <ProductgroupCard productgroup={p} loading={loading} />
        </Grid>
      ))}
      <Zoom in timeout={transitionDuration} unmountOnExit>
        <Fab
          className={classes.fab}
          color="primary"
          aria-label="add"
          onClick={handleAddOpen}
        >
          <AddIcon />
        </Fab>
      </Zoom>
      <Dialog open={adding} onClose={handleAddClose}>
        <ProductgroupForm
          onSubmit={handleAddSubmit}
          buttonText={t('common:add')}
          loading={addProductgroupLoading}
          cancelButton
          onCancel={handleAddClose}
        />
      </Dialog>
    </Grid>
  );
};

export default ProductgroupListMobile;
