import * as React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/hooks/useErrorMessage';

import UseHttpClient from '../utils/hooks/useHttpClient';
import useNotify from '../utils/hooks/useNotify';
import { AuthContext } from './authContext';

type SiteContext = {
  sites: Site[];
  getSitesLoading: boolean;
  addSiteLoading: boolean;
  editSiteLoading: boolean;
  deleteSiteLoading: boolean;
  getSites: (skipLoading?: boolean) => void;
  addSite: (siteToAdd: SiteFormDto, onSuccess?: () => void) => Promise<void>;
  editSite: (
    id: number,
    siteToEdit: SiteFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  deleteSite: (id: number, onSuccess?: () => void) => Promise<void>;
};

const SiteContext = createContext<SiteContext>({
  sites: [],
  getSitesLoading: false,
  addSiteLoading: false,
  editSiteLoading: false,
  deleteSiteLoading: false,
  getSites: () => null,
  addSite: () => Promise.reject(),
  editSite: () => Promise.reject(),
  deleteSite: () => Promise.reject(),
});

const SiteProvider: React.FC = ({ children }) => {
  const [sites, setSites] = useState<Site[]>([]);
  const [getSitesLoading, setGetSitesLoading] = useState(false);
  const [addSiteLoading, setAddSiteLoading] = useState(false);
  const [editSiteLoading, setEditSiteLoading] = useState(false);
  const [deleteSiteLoading, setDeleteSiteLoading] = useState(false);
  const { sendRequest } = UseHttpClient();
  const { t } = useTranslation('sites');
  const { errorMessage } = useErrorMessage();
  const { isLoggedIn } = useContext(AuthContext);

  const notify = useNotify();

  const getSites = useCallback(
    async (skipLoading = false) => {
      if (!skipLoading) {
        setGetSitesLoading(true);
      }
      try {
        const resData = await sendRequest<Site[]>({
          url: 'sites',
        });
        if (resData && resData.length) {
          setSites(resData);
        }
      } catch (error) {
        errorMessage({
          ns: 'sites',
          error,
          defaultTranslation: 'list',
        });
      } finally {
        if (!skipLoading) {
          setGetSitesLoading(false);
        }
      }
    },
    [sendRequest, errorMessage]
  );

  // Get all sites on mount
  useEffect(() => {
    if (isLoggedIn) {
      getSites();
    }
  }, [getSites, isLoggedIn]);

  const addSite = useCallback(
    async (siteToAdd: SiteFormDto, onSuccess?: () => void) => {
      setAddSiteLoading(true);
      try {
        await sendRequest({
          url: 'sites',
          method: 'POST',
          body: JSON.stringify(siteToAdd),
        });
        notify({ message: t('added') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'sites',
          error,
          defaultTranslation: 'add',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
          ],
        });
      } finally {
        setAddSiteLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const editSite = useCallback(
    async (id: number, siteToEdit: SiteFormDto, onSuccess?: () => void) => {
      setEditSiteLoading(true);
      try {
        await sendRequest({
          url: `sites/${id}`,
          method: 'PUT',
          body: JSON.stringify(siteToEdit),
        });
        notify({ message: t('edited') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'sites',
          error,
          defaultTranslation: 'edit',
        });
      } finally {
        setEditSiteLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const deleteSite = useCallback(
    async (id: number, onSuccess?: () => void) => {
      setDeleteSiteLoading(true);
      try {
        await sendRequest({
          url: `sites/${id}`,
          method: 'DELETE',
        });
        notify({ message: t('deleted') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'sites',
          error,
          defaultTranslation: 'delete',
        });
      } finally {
        setDeleteSiteLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  return (
    <SiteContext.Provider
      value={{
        sites,
        getSitesLoading,
        getSites,
        addSiteLoading,
        addSite,
        editSite,
        editSiteLoading,
        deleteSite,
        deleteSiteLoading,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export { SiteProvider, SiteContext };
