import * as React from 'react';
import { TableCell, TableCellProps, Grid, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';

interface Props {
  action?: 'edit' | 'expand';
  loading: boolean;
  editing: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
  align?: TableCellProps['align'];
  showDelete: boolean;
}

const ActionCell: React.FC<Props> = ({
  loading,
  editing,
  onEditClick,
  onDeleteClick,
  align = 'right',
  showDelete = true,
  action = 'edit'
}: Props) => {
  const { t } = useTranslation('common');
  const actionIconToUse = action === 'edit'
    ? <EditIcon />
    : <ExpandMore />;
  return (
    <TableCell align={align}>
      <Grid
        container
        direction="row"
        spacing={2}
        justify="flex-end"
        wrap="nowrap"
      >
        <Grid item>
          {loading ? (
            <Skeleton>
              <IconButton>
                {actionIconToUse}
              </IconButton>
            </Skeleton>
          ) : (
            <IconButton
              title={t(`${editing ? 'cancel' : 'edit'}`)}
              color="secondary"
              onClick={onEditClick}
            >
              {editing ? <CloseIcon /> : actionIconToUse}
            </IconButton>
          )}
        </Grid>
        <Grid item hidden={!showDelete}>
          {loading && showDelete ? (
            <Skeleton>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Skeleton>
          ) : (
            <IconButton
              title={t('delete')}
              color="secondary"
              onClick={onDeleteClick}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </TableCell>
  );
};

export default ActionCell;
