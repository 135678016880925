import * as React from 'react';
import { TabContext, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ReportList from './ReportList';
import ReportSearch, { AutoCompleteOption } from './ReportSearch';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
}));

const ReportListPage: React.FC = () => {
  const { t } = useTranslation('reports');
  const classes = useStyles();
  const [value, setValue] = React.useState<string>('0');
  const [selectedStorageFilter, setStorageFilter] = React.useState<AutoCompleteOption | undefined>();
  const [selectedSiteFilter, setSiteFilter] = React.useState<AutoCompleteOption | undefined>();

  return <Container maxWidth="lg">
    <Typography
      align="center"
      color="secondary"
      variant="h3"
      className={classes.title}
    >
      {t('reports')}
    </Typography>
    <TabContext value={value}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Tabs value={value} onChange={(_, newValue) => setValue(newValue)}>
          <Tab id='0' value='0' label={t('outOfStock')} style={{ color: value === '0' ? 'green' : 'black' }} />
          <Tab id='1' value='1' label={t('inStock')} style={{ color: value === '1' ? 'green' : 'black' }} />
        </Tabs>
        <ReportSearch onSiteChangedCb={f => setSiteFilter(f)} onStorageChangedCb={f => setStorageFilter(f)} />
      </div>
      <TabPanel value='0' style={{ padding: 0 }}>
        <ReportList inventoryReports={false} storageFilter={selectedStorageFilter} siteFilter={selectedSiteFilter} />
      </TabPanel>
      <TabPanel value='1' style={{ padding: 0 }}>
        <ReportList inventoryReports storageFilter={selectedStorageFilter} siteFilter={selectedSiteFilter} />
      </TabPanel>
    </TabContext>
  </Container>
}

export default ReportListPage;
