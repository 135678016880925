import { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactContext } from '../../store/contactContext';

export default function useGetContactInfo(): {
  getContactInfo: (
    id: number | null
  ) => { email: string; type: Contact['contactType'] | undefined };
} {
  const { t } = useTranslation('contacts');
  const { contacts } = useContext(ContactContext);

  const getContactInfo = useCallback(
    (id: number | null) => {
      if (id === null) {
        return { email: '', type: undefined };
      }
      const contact = contacts.find((s) => s.id === id);
      if (contact && contact.email) {
        return { email: contact.email, type: contact.contactType };
      }
      return { email: t('unknown'), type: undefined };
    },
    [contacts, t]
  );

  return { getContactInfo };
}
