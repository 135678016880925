import * as React from 'react';
import { useContext, useMemo, useEffect } from 'react';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Toolbar,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { UIContext } from '../store/uiContext';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: theme.languageDrawer.width,
    flexShrink: 0,
  },
  drawerPaper: {
    width: theme.languageDrawer.width,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontSize: '1.1875rem',
    fontWeight: theme.typography.fontWeightMedium,
  },
  activeLanguage: {
    color: theme.palette.primary.main,
  },
}));

const LanguageDrawer: React.FC = () => {
  const classes = useStyles();
  const { languageDrawerOpen, setLanguageDrawerOpen } = useContext(UIContext);
  const theme = useTheme();
  const { t, i18n } = useTranslation('language');
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const Languages = useMemo(
    () => [
      {
        id: 'fi',
        name: t('finnish'),
      },
      {
        id: 'en',
        name: t('english'),
      },
    ],
    [t]
  );

  // On mount, set the language from localstorage
  useEffect(() => {
    const lsLang = localStorage.getItem('language');
    if (lsLang) {
      i18n.changeLanguage(lsLang);
    }
  }, [i18n]);

  return (
    <Drawer
      className={classes.drawer}
      variant="temporary"
      open={languageDrawerOpen}
      anchor="right"
      onClose={() => setLanguageDrawerOpen(false)}
      classes={{
        paper: classes.drawerPaper,
      }}
      disablePortal
      ModalProps={{
        style: {
          zIndex: theme.zIndex.drawer,
        },
        BackdropProps: {
          invisible: true,
        },
      }}
    >
      <Toolbar />
      {isSmall && <Toolbar />}
      <div className={classes.drawerContainer}>
        <List>
          <ListItem divider disableGutters>
            <IconButton
              color="secondary"
              classes={{ root: classes.backButton }}
              onClick={() => setLanguageDrawerOpen(false)}
            >
              <ArrowRightAltIcon />
            </IconButton>
            <ListItemText
              primary={t('language')}
              classes={{ primary: classes.title }}
            />
          </ListItem>
          {Languages.map((l) => (
            <ListItem
              button
              divider
              key={l.id}
              onClick={() => {
                i18n.changeLanguage(l.id);
                localStorage.setItem('language', l.id);
              }}
            >
              <ListItemText
                primary={l.name}
                className={i18n.language === l.id ? classes.activeLanguage : ''}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default LanguageDrawer;
