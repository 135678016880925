import * as React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/hooks/useErrorMessage';

import UseHttpClient from '../utils/hooks/useHttpClient';
import useNotify from '../utils/hooks/useNotify';
import { AuthContext } from './authContext';

type ContactContext = {
  contacts: Contact[];
  getContactsLoading: boolean;
  addContactLoading: boolean;
  editContactLoading: boolean;
  deleteContactLoading: boolean;
  getContacts: (skipLoading?: boolean) => void;
  addContact: (
    contactToAdd: ContactFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  editContact: (
    id: number,
    contactToEdit: ContactFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  deleteContact: (id: number, onSuccess?: () => void) => Promise<void>;
};

const ContactContext = createContext<ContactContext>({
  contacts: [],
  getContactsLoading: false,
  addContactLoading: false,
  editContactLoading: false,
  deleteContactLoading: false,
  getContacts: () => null,
  addContact: () => Promise.reject(),
  editContact: () => Promise.reject(),
  deleteContact: () => Promise.reject(),
});

const ContactProvider: React.FC = ({ children }) => {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [getContactsLoading, setGetContactsLoading] = useState(false);
  const [addContactLoading, setAddContactLoading] = useState(false);
  const [editContactLoading, setEditContactLoading] = useState(false);
  const [deleteContactLoading, setDeleteContactLoading] = useState(false);
  const { sendRequest } = UseHttpClient();
  const { t } = useTranslation('contacts');
  const { errorMessage } = useErrorMessage();
  const { isLoggedIn } = useContext(AuthContext);

  const notify = useNotify();

  const getContacts = useCallback(
    async (skipLoading = false) => {
      if (!skipLoading) {
        setGetContactsLoading(true);
      }
      try {
        const resData = await sendRequest<Contact[]>({
          url: 'contacts',
        });
        if (resData && resData.length) {
          setContacts(resData);
        }
      } catch (error) {
        errorMessage({
          ns: 'contacts',
          error,
          defaultTranslation: 'list',
        });
      } finally {
        if (!skipLoading) {
          setGetContactsLoading(false);
        }
      }
    },
    [sendRequest, errorMessage]
  );

  // Get all contacts on mount
  useEffect(() => {
    if (isLoggedIn) {
      getContacts();
    }
  }, [getContacts, isLoggedIn]);

  const addContact = useCallback(
    async (contactToAdd: ContactFormDto, onSuccess?: () => void) => {
      setAddContactLoading(true);
      try {
        await sendRequest({
          url: 'contacts',
          method: 'POST',
          body: JSON.stringify(contactToAdd),
        });
        notify({ message: t('added') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'contacts',
          error,
          defaultTranslation: 'add',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
            {
              text: 'email: ',
              translation: 'email',
            },
          ],
        });
      } finally {
        setAddContactLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const editContact = useCallback(
    async (
      id: number,
      contactToEdit: ContactFormDto,
      onSuccess?: () => void
    ) => {
      setEditContactLoading(true);
      try {
        await sendRequest({
          url: `contacts/${id}`,
          method: 'PUT',
          body: JSON.stringify(contactToEdit),
        });
        notify({ message: t('edited') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'contacts',
          error,
          defaultTranslation: 'edit',
          cases: [
            {
              text: 'email: ',
              translation: 'email',
            },
          ],
        });
      } finally {
        setEditContactLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const deleteContact = useCallback(
    async (id: number, onSuccess?: () => void) => {
      setDeleteContactLoading(true);
      try {
        await sendRequest({
          url: `contacts/${id}`,
          method: 'DELETE',
        });
        notify({ message: t('deleted') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'contacts',
          error,
          defaultTranslation: 'delete',
        });
      } finally {
        setDeleteContactLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  return (
    <ContactContext.Provider
      value={{
        contacts,
        getContactsLoading,
        getContacts,
        addContactLoading,
        addContact,
        editContact,
        editContactLoading,
        deleteContact,
        deleteContactLoading,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
};

export { ContactProvider, ContactContext };
