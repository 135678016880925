import * as React from 'react';
import { useMemo } from 'react';
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    languageDrawer: {
      width: React.CSSProperties['width'];
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    languageDrawer?: {
      width?: React.CSSProperties['width'];
    };
  }
}

const UITheme: React.FC = ({ children }) => {
  const theme = useMemo(() => {
    return createTheme({
      languageDrawer: {
        width: 240,
      },
      palette: {
        primary: {
          main: '#1F9122',
          light: '#50B948',
        },
        secondary: {
          main: '#4C4D4F',
        },
        common: {
          black: '#000000',
          white: '#FFFFFF',
        },
      },
      typography: {
        fontFamily: 'Maven Pro, sans-serif',
      },
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default UITheme;
