import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useMediaQuery, useTheme, Container } from '@material-ui/core';

import AppBar from './AppBar';
import UITheme from './UITheme';
import LanguageDrawer from './LanguageDrawer';
import Notification from './Notification';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  main: {
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  container: {
    height: 'auto',
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
}));

const Layout: React.FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <UITheme>
      <div className={classes.root}>
        <AppBar />
        <LanguageDrawer />
        <main className={classes.main} id="main-content">
          <div className={classes.appBarSpacer} />
          {isSmall && <div className={classes.appBarSpacer} />}
          <Container maxWidth={false} className={classes.container}>
            <>{children}</>
          </Container>
        </main>
        <Notification />
      </div>
    </UITheme>
  );
};

export default Layout;
