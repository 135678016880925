import * as React from 'react';
import { useState, useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ProductgroupForm from './ProductgroupForm';
import { ProductgroupContext } from '../store/productgroupContext';
import DataCell from '../utils/components/DataCell';
import ActionCell from '../utils/components/ActionCell';
import DeleteDialog from '../utils/components/DeleteDialog';

type Props = {
  productgroup: Productgroup;
  loading: boolean;
  colCount: number;
};

const ProductgroupRow: React.FC<Props> = ({
  productgroup,
  loading,
  colCount,
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation('productgroups');
  const {
    getProductgroups,
    editProductgroup,
    editProductgroupLoading,
    deleteProductgroup,
    deleteProductgroupLoading,
  } = useContext(ProductgroupContext);

  const handleEditToggle = () => {
    setEditing((prevEditing) => !prevEditing);
  };

  const handleSubmit = (data: ProductgroupFormDto) => {
    editProductgroup(productgroup.id, data, () => {
      setEditing(false);
      getProductgroups(true);
    });
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDeleteConfirmation = () => {
    setDialogOpen(true);
  };

  const handleDelete = () => {
    deleteProductgroup(productgroup.id, () => {
      handleDialogClose();
      getProductgroups(true);
    });
  };

  return (
    <>
      <TableRow hover>
        <DataCell first loading={loading}>
          {productgroup.name}
        </DataCell>
        <ActionCell
          editing={editing}
          onEditClick={handleEditToggle}
          onDeleteClick={handleDeleteConfirmation}
          loading={loading}
          showDelete
        />
      </TableRow>
      {editing && (
        <TableRow>
          <TableCell colSpan={colCount}>
            <ProductgroupForm
              onSubmit={handleSubmit}
              defaultValues={productgroup}
              buttonText={t('common:edit')}
              loading={editProductgroupLoading}
            />
          </TableCell>
        </TableRow>
      )}
      <DeleteDialog
        loading={deleteProductgroupLoading}
        open={dialogOpen}
        onClose={handleDialogClose}
        ns="productgroups"
        onConfirm={handleDelete}
      />
    </>
  );
};

export default ProductgroupRow;
