import * as React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../utils/hooks/useErrorMessage';

import UseHttpClient from '../utils/hooks/useHttpClient';
import useNotify from '../utils/hooks/useNotify';
import { AuthContext } from './authContext';

type UserContext = {
  users: User[];
  getUsersLoading: boolean;
  addUserLoading: boolean;
  editUserLoading: boolean;
  deleteUserLoading: boolean;
  getUsers: (skipLoading?: boolean) => void;
  addUser: (userToAdd: UserFormDto, onSuccess?: () => void) => Promise<void>;
  editUser: (
    id: number,
    userToEdit: UserFormDto,
    onSuccess?: () => void
  ) => Promise<void>;
  deleteUser: (id: number, onSuccess?: () => void) => Promise<void>;
};

const UserContext = createContext<UserContext>({
  users: [],
  getUsersLoading: false,
  addUserLoading: false,
  editUserLoading: false,
  deleteUserLoading: false,
  getUsers: () => null,
  addUser: () => Promise.reject(),
  editUser: () => Promise.reject(),
  deleteUser: () => Promise.reject(),
});

const UserProvider: React.FC = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [getUsersLoading, setGetUsersLoading] = useState(false);
  const [addUserLoading, setAddUserLoading] = useState(false);
  const [editUserLoading, setEditUserLoading] = useState(false);
  const [deleteUserLoading, setDeleteUserLoading] = useState(false);
  const { sendRequest } = UseHttpClient();
  const { t } = useTranslation('users');
  const { errorMessage } = useErrorMessage();
  const { isLoggedIn } = useContext(AuthContext);

  const notify = useNotify();

  const getUsers = useCallback(
    async (skipLoading = false) => {
      if (!skipLoading) {
        setGetUsersLoading(true);
      }
      try {
        const resData = await sendRequest<User[]>({
          url: 'users',
        });
        if (resData && resData.length) {
          setUsers(resData);
        }
      } catch (error) {
        errorMessage({
          ns: 'users',
          error,
          defaultTranslation: 'list',
        });
      } finally {
        if (!skipLoading) {
          setGetUsersLoading(false);
        }
      }
    },
    [sendRequest, errorMessage]
  );

  // Get all users on mount
  useEffect(() => {
    if (isLoggedIn) {
      getUsers();
    }
  }, [getUsers, isLoggedIn]);

  const addUser = useCallback(
    async (userToAdd: UserFormDto, onSuccess?: () => void) => {
      setAddUserLoading(true);
      try {
        await sendRequest({
          url: 'users',
          method: 'POST',
          body: JSON.stringify(userToAdd),
        });
        notify({ message: t('added') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'users',
          error,
          defaultTranslation: 'add',
          cases: [
            {
              text: 'exists',
              translation: 'exists',
            },
            {
              text: 'email: ',
              translation: 'email',
            },
          ],
        });
      } finally {
        setAddUserLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const editUser = useCallback(
    async (id: number, userToEdit: UserFormDto, onSuccess?: () => void) => {
      setEditUserLoading(true);
      try {
        await sendRequest({
          url: `users/${id}`,
          method: 'PUT',
          body: JSON.stringify(userToEdit),
        });
        notify({ message: t('edited') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'users',
          error,
          defaultTranslation: 'edit',
          cases: [
            {
              text: 'email: ',
              translation: 'email',
            },
          ],
        });
      } finally {
        setEditUserLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  const deleteUser = useCallback(
    async (id: number, onSuccess?: () => void) => {
      setDeleteUserLoading(true);
      try {
        await sendRequest({
          url: `users/${id}`,
          method: 'DELETE',
        });
        notify({ message: t('deleted') });
        if (onSuccess) {
          onSuccess();
        }
      } catch (error) {
        errorMessage({
          ns: 'users',
          error,
          defaultTranslation: 'delete',
        });
      } finally {
        setDeleteUserLoading(false);
      }
    },
    [sendRequest, notify, t, errorMessage]
  );

  return (
    <UserContext.Provider
      value={{
        users,
        getUsersLoading,
        getUsers,
        addUserLoading,
        addUser,
        editUser,
        editUserLoading,
        deleteUser,
        deleteUserLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
