import * as React from 'react';
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
  Theme,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    backgroundColor: 'transparent',
    marginBottom: theme.spacing(1),
  },
  addContainer: {
    marginBottom: theme.spacing(4),
  },
}));

type RenderAddFormProps<T> = {
  onSubmit: (data: T) => void;
  buttonText: string;
};

type RenderBodyProps<T> = {
  data: T;
  loading: boolean;
  columnCount: number;
  index: number;
};

type Props<DataT extends DataRecord, FormT> = {
  headers: string[];
  data: DataT[];
  loading: boolean;
  ns: string;
  add: (formData: FormT, onSuccess: () => void) => void;
  get: (skipLoading?: boolean) => void;
  emptyKey: string;
  renderBody: ({
    data,
    loading,
    columnCount,
    index,
  }: RenderBodyProps<DataT>) => React.ReactNode;
  renderAddForm: ({
    onSubmit,
    buttonText,
  }: RenderAddFormProps<FormT>) => React.ReactNode;
  showAdd: boolean;
};

function DesktopList<DataT extends DataRecord, FormT>({
  headers,
  data,
  loading,
  ns,
  add,
  get,
  emptyKey,
  renderBody,
  renderAddForm,
  showAdd,
}: Props<DataT, FormT>): React.ReactElement | null {
  const classes = useStyles();
  const [adding, setAdding] = useState(false);
  const { t } = useTranslation(ns);

  const onSubmit = (formData: FormT) => {
    add(formData, () => {
      setAdding(false);
      get(true);
    });
  };

  const columnCount = headers.length + 1;

  return (
    <>
      <Grid
        container
        className={classes.toolbar}
        alignItems="center"
        justify="flex-end"
      >
        <Grid item hidden={!showAdd}>
          {adding ? (
            <Button startIcon={<CloseIcon />} onClick={() => setAdding(false)}>
              {t('common:cancel')}
            </Button>
          ) : (
            <Button startIcon={<AddIcon />} onClick={() => setAdding(true)}>
              {t('common:add')}
            </Button>
          )}
        </Grid>
      </Grid>
      {adding && showAdd && (
        <Paper className={classes.addContainer} elevation={6}>
          {renderAddForm({ onSubmit, buttonText: t('common:add') })}
        </Paper>
      )}
      <Paper elevation={6}>
        <Table stickyHeader>
          <TableHead>
            <TableRow hover>
              {headers.map((h) => (
                <TableCell key={h}>{t(h)}</TableCell>
              ))}
              <TableCell key="datacell" align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length ? (
              data.map((d, index) =>
                renderBody({ data: d, loading, columnCount, index })
              )
            ) : (
              <TableRow>
                <TableCell colSpan={columnCount}>
                  <Typography>{t(emptyKey)}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}

export default DesktopList;
