import * as React from 'react';
import { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SiteContext } from '../store/siteContext';
import SiteListMobile from './SiteListMobile';
import loadingSites from './loadingSites';
import DesktopList from '../utils/components/DesktopList';
import SiteForm from './SiteForm';
import SiteRow from './SiteRow';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
    },
  },
}));

const SiteList: React.FC = () => {
  const classes = useStyles();
  const {
    getSites,
    sites,
    getSitesLoading,
    addSite,
    addSiteLoading,
  } = useContext(SiteContext);
  const theme = useTheme();
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation('sites');

  useEffect(() => {
    getSites();
  }, [getSites]);

  return (
    <Container maxWidth="lg">
      <Typography
        align="center"
        color="secondary"
        variant="h3"
        className={classes.title}
      >
        {t('sites')}
      </Typography>
      {isMediumOrSmaller ? (
        <SiteListMobile
          sites={getSitesLoading ? loadingSites : sites}
          loading={getSitesLoading}
        />
      ) : (
        <DesktopList<Site, SiteFormDto>
          headers={['name', 'location', 'users', 'contacts']}
          data={getSitesLoading ? loadingSites : sites}
          loading={getSitesLoading}
          ns="sites"
          add={addSite}
          get={getSites}
          emptyKey="noSites"
          renderAddForm={({ onSubmit, buttonText }) => (
            <SiteForm
              loading={addSiteLoading}
              onSubmit={onSubmit}
              buttonText={buttonText}
            />
          )}
          renderBody={({ data, loading, columnCount }) => (
            <SiteRow
              key={data.id}
              site={data}
              loading={loading}
              colCount={columnCount}
            />
          )}
          showAdd
        />
      )}
    </Container>
  );
};

export default SiteList;
