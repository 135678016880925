import * as React from 'react';
import { useEffect, useContext } from 'react';
import { Pagination } from '@material-ui/lab';
import { Container, MenuItem, Select, Box } from '@material-ui/core';
import { ReportContext } from '../store/reportContext';
import loadingReports from './loadingReports';
import DesktopList from '../utils/components/DesktopList';
import ReportForm from './ReportForm';
import ReportRow from './ReportRow';
import { AutoCompleteOption } from './ReportSearch';

interface Props {
    inventoryReports: boolean;
    storageFilter?: AutoCompleteOption;
    siteFilter?: AutoCompleteOption;
}

type PageSize = 25 | 50 | 100 | 200;
const defaultPageSize: PageSize = 25;

const ReportList: React.FC<Props> = (props) => {

    const { inventoryReports, siteFilter, storageFilter } = props;

    const {
        getReports,
        getReportsLoading,
        addReport,
        addReportLoading,
    } = useContext(ReportContext);

    const [reports, setReports] = React.useState<TableModel<Report>>();
    const [pageOptions, setPageOptions] = React.useState<{ page: number; pageSize: PageSize }>({ page: 0, pageSize: defaultPageSize });

    const storageFilterToUse = React.useMemo<any>(() => storageFilter ? { storageId: storageFilter.id } : {}, [storageFilter]);
    const siteFilterToUse = React.useMemo<any>(() => siteFilter ? { siteId: siteFilter.id } : {}, [siteFilter]);

    const handleChangePageSize = (pageSizeToSet: PageSize) => {
        setPageOptions({ pageSize: pageSizeToSet, page: 0, ...siteFilterToUse, ...storageFilterToUse });
    }

    useEffect(() => {
        getReports(false, { inventoryReports, pageSize: pageOptions.pageSize, page: pageOptions.page, ...siteFilterToUse, ...storageFilterToUse }, setReports);
    }, [getReports, inventoryReports, pageOptions.page, pageOptions.pageSize, siteFilterToUse, storageFilterToUse]);

    if (!reports) {
        return <div>Loading</div>;
    }

    return (
        <Container style={{ padding: 0 }}>
            <DesktopList<Report, ReportFormDto>
                headers={[
                    'siteName',
                    'storageName',
                    'reporterName',
                    'date',
                    ''
                ]}
                data={getReportsLoading ? loadingReports : reports.reportHeaders}
                loading={getReportsLoading}
                ns="reports"
                add={addReport}
                get={(_?: boolean) => { return null; }}
                emptyKey="noReports"
                renderAddForm={({ onSubmit, buttonText }) => (
                    <ReportForm
                        loading={addReportLoading}
                        onSubmit={onSubmit}
                        buttonText={buttonText}
                    />
                )}
                renderBody={({ data, loading, columnCount }) => (
                    <ReportRow
                        key={data.id}
                        report={data}
                        loading={loading}
                        colCount={columnCount}
                    />
                )}
                showAdd={false}
            />
            <Box style={{ marginTop: '1em', display: 'flex', justifyContent: 'space-between' }}>
                <Pagination
                    page={pageOptions.page + 1}
                    count={reports?.totalPages}
                    onChange={(_, value) => setPageOptions(prev => ({ ...prev, page: value - 1 }))}
                    variant="outlined"
                    shape="rounded" />
                <Select
                    labelId="select-label"
                    id="simple-select"
                    value={pageOptions.pageSize}
                    label="Page size"
                    onChange={(event) => handleChangePageSize(event.target.value as PageSize)}
                >
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                </Select>
            </Box>
        </Container >
    );
};

export default ReportList;